import React, { useState, useMemo, useEffect, useCallback } from 'react'
import ReactPlaceholder from 'react-placeholder/lib'
import Controllers from '../../Api/Controllers'
import { Input, FormCustom, Select, SwitchToggle } from '../../Components/Form/Form'
import Helpers from '../../Components/Helpers/Helpers'
import { toast } from 'react-toastify';
import Button from 'react-bootstrap/esm/Button'
import Loading from '../../Components/Layout/Styled/Loading'

const CustomersForm = ({ data, onSubmit, disablebSubmit, loadSubmit, bank, social, saveMyCustomers }) => {
    const [ready, setReady] = useState(false)
    // tipo de cliente, si es de Mexico 1, o si es de otro país 2 (USA)
    const [typeCustomers, setTypeCustomers] = useState(parseInt(data.id_country) > 1 ? 2 : 1)
    const [row, setRow] = useState({
        p_id_customers: data.id_customers,
        p_name_customers: data.name_customers,
        p_last_name_customers: data.last_name_customers,
        p_last_name_mot_customers: data.last_name_mot_customers,
        p_zip_code_customers: data.zip_code_customers,
        p_department_customers: data.department_customers,
        p_province_customers: data.province_customers,
        p_address_customers: data.address_customers,
        p_ife_customers: data.ife_customers,
        p_curp_customers: data.curp_customers,
        p_rfc_customers: data.rfc_customers,
        p_phone_customers: data.phone_customers,
        p_email_customers: data.email_customers,
        p_date_birthday_customers: data.date_birthday_customers,
        p_number_secure_social_customers: data.number_secure_social_customers,
        p_date_registration: data.date_registration,
        p_id_status: data.id_status,
        p_id_type_price: data.id_type_price,
        p_id_upline: data.id_upline,
        p_id_sponsor: data.id_upline,
        p_id_branch_office: data.id_branch_office,
        p_id_country: data.id_country,
        p_id_language: data.id_language,
        p_file_contract: data.file_contract,
        p_name_upline: data.name_upline,
        p_password_user: isNaN(parseInt(data.id_customers)) ? generatePassword() : data.password_user,
        p_file_photo_customers: data.file_photo_customers,
        p_save_online: saveMyCustomers ? 1 : data.save_online,
        p_pay_membership: saveMyCustomers ? 0 : data.pay_membership
    })

    const [viewPassword, setViewPassword] = useState(false)
    const [isSponsorOk, setIsSponsorOk] = useState(data.id_sponsor !== '')
    const [rowsCountry, setRowsCountry] = useState([])
    const [rowsBranchOffice, setRowsBranchOffice] = useState([])
    const [rowsTypePrice, setRowsTypePrice] = useState([])
    const [rowsLanguage, setRowsLanguage] = useState([])
    const [rowsBank, setRowsBank] = useState([])
    const [rowsTypeFormatPay, setRowsTypeFormatPay] = useState([])
    const [bankCustomers, setBankCustomers] = useState({
        p_id_customers_bank: bank.id_customers_bank,
        p_id_bank: bank.id_bank,
        p_number_account_bank: bank.number_account_bank,
        p_id_type_format_pay: bank.id_type_format_pay,
    })

    const [showLoading, setShowLoading] = useState(false)
    const [socialCustomers, setSocialCustomers] = useState(social)

    const country = useMemo(() => {
        if (!row.p_id_country) {
            return
        }

        let finded = rowsCountry.find(t => parseInt(row.p_id_country) === parseInt(t.id_country))

        if (!finded) {
            return
        }
        return {
            label: finded.name_country,
            value: finded.id_country
        }
    }, [row.p_id_country, rowsCountry])

    const branchOffice = useMemo(() => {
        if (!row.p_id_branch_office) {
            return
        }

        let finded = rowsBranchOffice.find(t => parseInt(row.p_id_branch_office) === parseInt(t.id_branch_office))

        if (!finded) {
            return
        }
        return {
            label: finded.name_branch_office,
            value: finded.id_branch_office
        }
    }, [row.p_id_branch_office, rowsBranchOffice])

    const language = useMemo(() => {
        if (!row.p_id_language) {
            return
        }

        let finded = rowsLanguage.find(t => parseInt(row.p_id_language) === parseInt(t.id_language))

        if (!finded) {
            return
        }
        return {
            label: finded.name_language,
            value: finded.id_language
        }
    }, [row.p_id_language, rowsLanguage])

    const typePrice = useMemo(() => {
        if (!row.p_id_type_price) {
            return
        }

        let finded = rowsTypePrice.find(t => parseInt(row.p_id_type_price) === parseInt(t.id_type_price))

        if (!finded) {
            return
        }
        return {
            label: finded.name_price,
            value: finded.id_type_price
        }
    }, [row.p_id_type_price, rowsTypePrice])

    const bankSelected = useMemo(() => {
        if (!bankCustomers.p_id_bank) {
            return
        }

        let finded = rowsBank.find(t => parseInt(bankCustomers.p_id_bank) === parseInt(t.id_bank))

        if (!finded) {
            return
        }
        return {
            label: finded.name_bank,
            value: finded.id_bank
        }
    }, [bankCustomers.p_id_bank, rowsBank])

    const typeFomatPaySelected = useMemo(() => {
        if (!bankCustomers.p_id_type_format_pay) {
            return
        }

        let finded = rowsTypeFormatPay.find(t => parseInt(bankCustomers.p_id_type_format_pay) === parseInt(t.id_type_format_pay))

        if (!finded) {
            return
        }
        return {
            label: finded.name_format_pay,
            value: finded.id_type_format_pay
        }
    }, [bankCustomers.p_id_type_format_pay, rowsTypeFormatPay])

    const handleValidateSponsor = _ => {
        setShowLoading(true)
        Controllers.customers[saveMyCustomers ? 'get_search_my_refer' : 'get_customers_validate']({
            p_id_customers: row.p_id_upline
        }).then(res => {
            if (!res.data?.id_customers) {
                setIsSponsorOk(false)
                setRow({ ...row, p_id_upline: '', p_name_upline: '' })
            } else {
                setIsSponsorOk(true)
                if (saveMyCustomers) {
                    setRow({
                        ...row,
                        p_id_upline: res.data.id_customers,
                        p_name_upline: res.data.full_name_customers
                    })
                } else {
                    setRow({
                        ...row,
                        p_id_upline: res.data.id_customers,
                        p_name_upline: `${res.data.last_name_customers} ${res.data.last_name_mot_customers} ${res.data.name_customers}`
                    })
                }
            }
        }).finally(_ => setShowLoading(false))
    }

    useEffect(() => {
        let promises = [
            Controllers.country.get_country(),
            Controllers.branch_office.get_branch_office(),
            Controllers.language.get_language(),
            Controllers.type_price.get_type_price(),
            Controllers.bank.get_bank(),
            Controllers.type_format_pay.get_type_format_pay()
        ]

        Promise.all(promises).then(res => {
            setRowsCountry(res[0].data)
            setRowsBranchOffice(res[1].data)
            setRowsLanguage(res[2].data)
            setRowsTypePrice(res[3].data)
            setRowsBank(res[4].data)
            setRowsTypeFormatPay(res[5].data)
            setReady(true)
        })
    }, [])

    const dataSubmit = useCallback(() => {
        let formData = new FormData()

        for (const key in row) {
            if (typeCustomers === 1) {
                if (key === 'p_number_secure_social_customers') {
                    continue
                }
            } else if (typeCustomers === 2) {
                if (key === 'p_ife_customers' || key === 'p_curp_customers' || key === 'p_rfc_customers') {
                    continue
                }
            }

            if (key === 'p_id_status') {
                formData.append('p_id_status', parseInt(row.p_id_status) ? 1 : 0)
                continue
            }

            if (key === 'p_file_contract') {
                if (row.p_file_contract?.files) {
                    if (row.p_file_contract.files.length > 0) {
                        formData.append('p_file_contract', row.p_file_contract.files[0])
                        continue
                    }
                }
                formData.append('p_file_contract', '')
                continue
            }

            if (key === 'p_file_photo_customers') {
                if (row.p_file_photo_customers?.files) {
                    if (row.p_file_photo_customers.files.length > 0) {
                        formData.append('p_file_photo_customers', row.p_file_photo_customers.files[0])
                        continue
                    }
                }
                formData.append('p_file_photo_customers', '')
                continue
            }
            formData.append(key, row[key])
        }

        formData.append('p_customers_bank', JSON.stringify([bankCustomers]))
        formData.append('p_customers_social', JSON.stringify(socialCustomers.map(s => {
            return {
                p_id_customers_social: s.id_customers_social,
                p_type_social: s.type_social,
                p_url_social: s.url_social
            }
        })))

        return formData
    }, [row, bankCustomers, socialCustomers, typeCustomers])

    return <ReactPlaceholder ready={ready} rows={15} showLoadingAnimation>
        <FormCustom dataSubmit={dataSubmit()} onSubmit={({ data }) => {
            if (!isSponsorOk) {
                Helpers.toast.construct({ response: 'warning', message: 'Por favor, verifique el Sponsor/Upline', toast })
                return
            }
            if (!row.p_id_branch_office) {
                Helpers.toast.construct({ response: 'warning', message: 'Por favor, seleccionar la sucursal', toast })
                return
            }
            if (!row.p_id_type_price) {
                Helpers.toast.construct({ response: 'warning', message: 'Por favor, seleccionar el tipo de precio', toast })
                return
            }
            if (!row.p_id_language) {
                Helpers.toast.construct({ response: 'warning', message: 'Por favor, seleccionar el idioma', toast })
                return
            }
            if (!row.p_id_country) {
                Helpers.toast.construct({ response: 'warning', message: 'Por favor, seleccionar el país', toast })
                return
            }
            if (!row.p_id_country) {
                Helpers.toast.construct({ response: 'warning', message: 'Por favor, seleccionar la sucursal', toast })
                return
            }
            if (!row.p_id_branch_office) {
                Helpers.toast.construct({ response: 'warning', message: 'Por favor, seleccionar la sucursal', toast })
                return
            }
            if (!row.p_id_branch_office) {
                Helpers.toast.construct({ response: 'warning', message: 'Por favor, seleccionar la sucursal', toast })
                return
            }
            if (bankCustomers.p_number_account_bank && (!bankCustomers.p_id_bank || !bankCustomers.p_id_type_format_pay)) {
                Helpers.toast.construct({ response: 'warning', message: 'Por favor, completar información del banco', toast })
                return
            }
            onSubmit({ data })
        }} disablebSubmit={disablebSubmit} loadSubmit={loadSubmit}>
            <p className="col-12 mb-0">Información Sponsor</p>
            <Input
                text={<>Upline <strong className={`text-${isSponsorOk ? 'success' : 'danger'}`}><i className={`fa fa-${isSponsorOk ? 'check' : 'times'} mr-1 ml-2`}></i> {isSponsorOk ? 'Verificado' : 'No Verificado'}</strong></>}
                placeholder="Upline"
                type="number"
                classNameParent="col-6 mb-2"
                icon="fas fa-sort-numeric-up"
                required
                invalid="El campo es obligatorio."
                value={row.p_id_upline}
                min="1"
                onChange={e => {
                    if (!data.id_customers) {
                        setRow({ ...row, p_id_upline: e.currentTarget.value, p_name_upline: '' })
                        if (e.currentTarget.value.trim() === '') {
                            setIsSponsorOk(false)
                            return
                        }

                        setIsSponsorOk(parseInt(e.currentTarget.value.trim()) === parseInt(data.id_sponsor))
                    }
                }}
                append={data.id_customers ? [] : [<Button variant="info" disabled={isSponsorOk} onClick={handleValidateSponsor}>
                    <i className="fa fa-search"></i>
                </Button>]}
                disabled={data.id_customers !== ''}
            />
            <Input
                text="Sponsor"
                placeholder="Sponsor"
                type="number"
                classNameParent="col-6 mb-2"
                icon="fas fa-sort-numeric-up"
                required
                invalid="El campo es obligatorio."
                value={row.p_id_upline}
                disabled
            />
            <Input
                text="Nombre Upline"
                placeholder="Nombre Upline"
                classNameParent="col-12 mb-2"
                icon="far fa-user"
                value={row.p_name_upline}
                disabled
            />

            {saveMyCustomers || parseInt(data.save_online) !== 1 ? '' : <SwitchToggle
                id="customers-pay-membership"
                defaultChecked={parseInt(data.pay_membership) === 1}
                onChange={e => setRow({ ...row, p_pay_membership: e.currentTarget.checked ? 1 : 0 })}
                classNameParent="col-12 mb-2"
                textCheck="Membresía Pagada"
                textUnChecked="Membresía por Pagar"
            />}

            <p className="col-12 mb-0 mt-2 font-weight-bold">Información Cliente</p>
            <Input
                text="Fecha Ingreso"
                placeholder="Fecha Ingreso"
                type="date"
                classNameParent="col-6 mb-2"
                icon="far fa-calendar-alt"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.date_registration}
                onChange={e => setRow({ ...row, p_date_registration: e.currentTarget.value })}
                maxLength={30}
            />
            <Input
                text="Nombre"
                placeholder="Nombre"
                classNameParent="col-6 mb-2"
                icon="far fa-user"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.name_customers}
                onChange={e => setRow({ ...row, p_name_customers: e.currentTarget.value })}
                maxLength={150}
            />
            <Input
                text="Apellido Paterno"
                placeholder="Apellido Paterno"
                classNameParent="col-6 mb-2"
                icon="far fa-user"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.last_name_customers}
                onChange={e => setRow({ ...row, p_last_name_customers: e.currentTarget.value })}
                maxLength={150}
            />
            <Input
                text="Apellido Materno"
                placeholder="Apellido Materno"
                classNameParent="col-6 mb-2"
                icon="far fa-user"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.last_name_mot_customers}
                onChange={e => setRow({ ...row, p_last_name_mot_customers: e.currentTarget.value })}
                maxLength={150}
            />
            <Input
                text="Teléfono"
                placeholder="Teléfono"
                classNameParent="col-6 mb-2"
                icon="fa fa-phone"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.phone_customers}
                onChange={e => setRow({ ...row, p_phone_customers: e.currentTarget.value })}
                maxLength={30}
            />
            <Input
                text="Email"
                type="email"
                placeholder="Email"
                classNameParent="col-6 mb-2"
                icon="fa fa-envelope"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.email_customers}
                onChange={e => setRow({ ...row, p_email_customers: e.currentTarget.value })}
                maxLength={50}
            />
            <Input
                type="date"
                text="Fecha Nacimiento"
                placeholder="Fecha Nacimiento"
                classNameParent="col-6 mb-2"
                icon="far fa-calendar-alt"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.date_birthday_customers}
                onChange={e => setRow({ ...row, p_date_birthday_customers: e.currentTarget.value })}
                maxLength={30}
            />
            <div className="col-6 mb-2">
                <Select
                    classNameParent="w-100"
                    icon="far fa-building"
                    text="Sucursal"
                    options={rowsBranchOffice.map(t => {
                        return {
                            label: t.name_branch_office,
                            value: t.id_branch_office
                        }
                    })}
                    value={branchOffice}
                    onChange={e => setRow({ ...row, p_id_branch_office: e.value })}
                />
                {saveMyCustomers ? <small className="text-info">Si no hay una sucursal en tu estado selecciona Irapuato.</small> : ''}
            </div>
            <Select
                classNameParent="col-6 mb-2"
                icon="fas fa-dollar-sign"
                text="Tipo Precio"
                options={rowsTypePrice.map(t => {
                    return {
                        label: t.name_price,
                        value: t.id_type_price
                    }
                })}
                value={typePrice}
                onChange={e => setRow({ ...row, p_id_type_price: e.value })}
            />
            <Select
                classNameParent="col-6 mb-2"
                icon="fas fa-language"
                text="Idioma"
                options={rowsLanguage.map(t => {
                    return {
                        label: t.name_language,
                        value: t.id_language
                    }
                })}
                value={language}
                onChange={e => setRow({ ...row, p_id_language: e.value })}
            />
            {saveMyCustomers ? '' : <SwitchToggle
                id="customers-status"
                defaultChecked={parseInt(data.id_status) === 1}
                onChange={e => setRow({ ...row, p_id_status: e.currentTarget.checked ? 1 : 0 })}
                classNameParent="col-12 mb-2"
                textUnChecked="De Baja"
            />}

            <Input
                icon="fa fa-image"
                type="file"
                text="Imagen"
                classNameParent="col-12 mb-2"
                accept="image/png, image/jpe"
                onChange={e => setRow({ ...row, p_file_photo_customers: e.currentTarget })}
            />
            {data.file_photo_customers ? <div className="col-12">
                <a href={Helpers.config.resourcesUrl({ url: data.file_photo_customers })} target="_blank">
                    <img src={Helpers.config.resourcesUrl({ url: data.file_photo_customers })} width="300" alt={data.alt_photo} className="img-thumbnail" />
                </a>
            </div> : ''}

            <p className="col-12 mb-0 mt-2 font-weight-bold">Información Domicilio</p>
            <Select
                classNameParent="col-12 mb-2"
                icon="far fa-flag"
                text="País"
                options={rowsCountry.map(t => {
                    return {
                        label: t.name_country,
                        value: t.id_country
                    }
                })}
                value={country}
                onChange={e => {
                    setRow({ ...row, p_id_country: e.value })
                    setTypeCustomers(e.value === 1 ? 1 : 2)
                }}
            />

            <Input
                text="Código Postal"
                placeholder="Código Postal"
                classNameParent="col-6 mb-2"
                icon="fas fa-map-pin"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.zip_code_customers}
                onChange={e => setRow({ ...row, p_zip_code_customers: e.currentTarget.value })}
                maxLength={20}
            />
            <Input
                text="Estado"
                placeholder="Estado"
                classNameParent="col-6 mb-2"
                icon="fas fa-map-marker-alt"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.department_customers}
                onChange={e => setRow({ ...row, p_department_customers: e.currentTarget.value })}
                maxLength={100}
            />
            <Input
                text="Ciudad"
                placeholder="Ciudad"
                classNameParent="col-6 mb-2"
                icon="fas fa-map-marker-alt"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.province_customers}
                onChange={e => setRow({ ...row, p_province_customers: e.currentTarget.value })}
                maxLength={100}
            />
            <Input
                text="Dirección"
                placeholder="Dirección"
                classNameParent="col-6 mb-2"
                icon="fas fa-map-marker-alt"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.address_customers}
                onChange={e => setRow({ ...row, p_address_customers: e.currentTarget.value })}
                maxLength={150}
            />

            <p className="col-12 mb-0 mt-2 font-weight-bold">Información Documentaria</p>

            {typeCustomers === 1 ? <>
                <Input
                    text="IME"
                    placeholder="IME"
                    classNameParent="col-6 mb-2"
                    icon="far fa-id-card"
                    required
                    invalid="El campo es obligatorio."
                    defaultValue={data.ife_customers}
                    onChange={e => setRow({ ...row, p_ife_customers: e.currentTarget.value })}
                    maxLength={30}
                />
                <Input
                    text="CURP"
                    placeholder="CURP"
                    classNameParent="col-6 mb-2"
                    icon="far fa-id-card"
                    required
                    maxLength={18}
                    pattern="[0-9A-Za-z]{18}"
                    invalid="Por favor, ingresar texto alfumérico de 18 caracteres."
                    defaultValue={data.curp_customers}
                    onChange={e => setRow({ ...row, p_curp_customers: e.currentTarget.value })}
                />
                <Input
                    text="RFC"
                    placeholder="RFC"
                    classNameParent="col-6 mb-2"
                    icon="far fa-id-card"
                    required
                    defaultValue={data.rfc_customers}
                    onChange={e => setRow({ ...row, p_rfc_customers: e.currentTarget.value })}
                    maxLength={13}
                    pattern="[0-9A-Za-z]{13}"
                    invalid="Por favor, ingresar texto alfumérico de 13 caracteres."
                />
            </> : <Input
                text="Número Seguro"
                placeholder="Número Seguro"
                classNameParent="col-6 mb-2"
                icon="far fa-id-card"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.number_secure_social_customers}
                onChange={e => setRow({ ...row, p_number_secure_social_customers: e.currentTarget.value })}
                maxLength={30}
            />}
            {data.file_contract ? <>
                <div className="col-6 mb-2"></div>
                <div className="col-6 align-self-center mb-2 mt-2">
                    <a href={Helpers.config.resourcesUrl({ url: data.file_contract })} target="_blank">
                        <Button variant="danger" size="sm"><i className="fa fa-file-pdf mr-1"></i> Ver Contrato</Button>
                    </a>
                </div>
            </> : ''}
            <Input
                type="file"
                text="Documento Contrato"
                placeholder="Documento Contrato"
                classNameParent="col-12 mb-2"
                accept="application/pdf"
                onChange={e => setRow({ ...row, p_file_contract: e.currentTarget })}
            />

            <p className="col-12 mb-0 mt-2 font-weight-bold">Información Bancaria</p>
            <Select
                classNameParent="col-12 mb-2"
                icon="fas fa-university"
                text="Banco"
                options={rowsBank.map(t => {
                    return {
                        label: t.name_bank,
                        value: t.id_bank
                    }
                })}
                value={bankSelected}
                onChange={e => setBankCustomers({ ...bankCustomers, p_id_bank: e.value })}
            />
            <Input
                text="Nro Cuenta Bancaria"
                placeholder="Nro Cuenta Bancaria"
                classNameParent="col-6 mb-2"
                icon="fas fa-sort-numeric-up"
                invalid="El campo es obligatorio."
                defaultValue={bank.number_account_bank}
                required={bankCustomers.p_id_bank !== '' || bankCustomers.p_id_type_format_pay !== ''}
                onChange={e => setBankCustomers({ ...bankCustomers, p_number_account_bank: e.currentTarget.value })}
                maxLength={100}
            />
            <Select
                classNameParent="col-6 mb-2"
                icon="fas fa-file-invoice-dollar"
                text="Formato De Pago"
                options={rowsTypeFormatPay.map(t => {
                    return {
                        label: t.name_format_pay,
                        value: t.id_type_format_pay
                    }
                })}
                value={typeFomatPaySelected}
                onChange={e => setBankCustomers({ ...bankCustomers, p_id_type_format_pay: e.value })}
            />
            <p className="col-12 mb-0 mt-2 font-weight-bold">Información Redes Sociales</p>
            {React.Children.toArray(socialCustomers.map(s => {
                return <>
                    <Input
                        text={Helpers.string.toCamelCase(s.type_social.toLowerCase())}
                        placeholder={Helpers.string.toCamelCase(s.type_social.toLowerCase())}
                        classNameParent="col-12 mb-2"
                        icon="far fa-comment-alt"
                        value={s.url_social}
                        onChange={e => setSocialCustomers(socialCustomers.map(ss => {
                            if (ss.type_social === s.type_social) {
                                return {
                                    ...ss,
                                    url_social: e.currentTarget.value
                                }
                            }

                            return ss
                        }))}
                        maxLength={100}
                    />
                </>
            }))}
            <p className="col-12 mb-0 mt-2 font-weight-bold">Información Sistema</p>
            <Input
                classNameParent="col-6"
                text="Usuario"
                defaultValue={data.id_customers ? data.id_customers : 'Auto Generado'}
                disabled
                className="text-center"
            />
            <Input
                classNameParent="col-6 mb-2"
                text="Contraseña"
                type={viewPassword ? 'text' : 'password'}
                value={row.p_password_user}
                placeholder="Contraseña"
                className="text-center"
                required
                onChange={e => setRow({ ...row, p_password_user: e.currentTarget.value })}
                append={[
                    <Button variant="info" onMouseEnter={_ => setViewPassword(true)} onMouseLeave={_ => setViewPassword(false)}>
                        <i className="fa fa-eye"></i>
                    </Button>
                ]}
            />
        </FormCustom>
        {saveMyCustomers ? <div className="alert alert-info mt-3">Para activar a tu consultor comunícate con nuestro Call Center.</div> : ''}
        <Loading show={showLoading}>
            <Loading.Img src={Helpers.config.resourcesUrl({ url: '/images/dashboard/preloading.svg' })} alt="Preloading" />
        </Loading>
    </ReactPlaceholder>
}

function generatePassword() {
    var length = 8,
        charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
        retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
}

CustomersForm.defaultProps = {
    data: {
        id_customers: '',
        name_customers: '',
        last_name_customers: '',
        last_name_mot_customers: '',
        zip_code_customers: '',
        department_customers: '',
        province_customers: '',
        address_customers: '',
        ife_customers: '',
        curp_customers: '',
        rfc_customers: '',
        phone_customers: '',
        email_customers: '',
        date_birthday_customers: '',
        number_secure_social_customers: '',
        date_registration: '',
        id_status: 0,
        id_type_price: '',
        id_upline: '',
        id_sponsor: '',
        id_branch_office: '',
        id_country: '',
        id_language: '',
        id_sponsor: '',
        file_contract: '',
        name_upline: '',
        password_user: '',
        file_photo_customers: '',
        save_online: 0,
        pay_membership: 0
    },
    bank: {
        id_customers_bank: '',
        id_bank: '',
        number_account_bank: '',
        id_type_format_pay: ''
    },
    social: [
        { id_customers_social: '', type_social: 'FACEBOOK', url_social: '' },
        { id_customers_social: '', type_social: 'TWITTER', url_social: '' },
        { id_customers_social: '', type_social: 'INSTAGRAM', url_social: '' },
        { id_customers_social: '', type_social: 'LINKEDIN', url_social: '' },
    ],
    onSubmit: () => { },
    loadSubmit: false,
    saveMyCustomers: false
}

export default CustomersForm;