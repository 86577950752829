import React, { useState, useEffect, useMemo } from 'react'
import Card from 'react-bootstrap/esm/Card'
import Controllers from '../../Api/Controllers'
import ReactPlaceholder from 'react-placeholder/lib'
import Helpers from '../../Components/Helpers/Helpers'
import { FormCustom, Select, CheckBox } from '../../Components/Form/Form'
import { toast } from 'react-toastify'

const CopyProducts = _ => {
    const [ready, setReady] = useState(false)
    const [rowsBranchOffice, setRowsBrachOffice] = useState([])
    const [rowProduct, setRowProduct] = useState({
        p_id_branch_office_origin: '',
        p_id_type_money_origin: ''
    })
    const [rowProductPrice, setRowProductPrice] = useState({
        p_id_branch_office_origin: '',
        p_id_type_money_origin: ''
    })
    const [loadSubmitProduct, setLoadSubmitProduct] = useState(false)
    const [loadSubmitProductPrice, setLoadSubmitProductPrice] = useState(false)

    useEffect(() => {
        Controllers.branch_office.get_branch_office().then(res => {
            setRowsBrachOffice(res.data)
            setReady(true)
        })
    }, [])

    const branchOfficeOriginProduct = useMemo(() => {
        if (!rowProduct.p_id_branch_office_origin) {
            return
        }

        let finded = rowsBranchOffice.find(t => parseInt(t.id_branch_office) === parseInt(rowProduct.p_id_branch_office_origin))

        if (!finded) {
            return
        }

        return {
            label: finded.name_branch_office,
            value: finded.id_branch_office
        }
    }, [rowProduct.p_id_branch_office_origin, rowsBranchOffice])

    const branchOfficeOriginProductPrice = useMemo(() => {
        if (!rowProductPrice.p_id_branch_office_origin) {
            return
        }

        let finded = rowsBranchOffice.find(t => parseInt(t.id_branch_office) === parseInt(rowProductPrice.p_id_branch_office_origin))

        if (!finded) {
            return
        }

        return {
            label: finded.name_branch_office,
            value: finded.id_branch_office
        }
    }, [rowProductPrice.p_id_branch_office_origin, rowsBranchOffice])

    const onSubmitProduct = ({ data }) => {
        setLoadSubmitProduct(true)
        Controllers.branch_office.branch_office_copy_products(data).then(res => {
            Helpers.toast.construct({ ...res, toast })
        }).catch(req => Helpers.promise.catch({ req, toast }))
            .finally(() => {
                setLoadSubmitProduct(false)
            })
    }

    const onSubmitProductPrice = ({ data }) => {
        setLoadSubmitProductPrice(true)
        Controllers.branch_office.branch_office_copy_products_price(data).then(res => {
            Helpers.toast.construct({ ...res, toast })
        }).catch(req => Helpers.promise.catch({ req, toast }))
            .finally(() => {
                setLoadSubmitProductPrice(false)
            })
    }

    return <>
        <Card className="mb-4">
            <Card.Header>
                Replicar definición de producto a otro almacén
            </Card.Header>
            <Card.Body>
                <ReactPlaceholder ready={ready} showLoadingAnimation rows={5}>
                    <FormCustom loadSubmit={loadSubmitProduct} dataSubmit={rowProduct} onSubmit={({ data }) => {
                        if (!rowProduct.p_id_branch_office_origin) {
                            Helpers.toast.construct({ response: 'warning', message: 'Seleccionar sucursal origen', toast })
                            return
                        }

                        if (!rowsBranchOffice.find(b => parseInt(b.checked_copy_products) === 1)) {
                            Helpers.toast.construct({ response: 'warning', message: 'Seleccionar sucursales a replicar', toast })
                            return
                        }

                        data.p_branch_office_destination = rowsBranchOffice.filter(b => parseInt(b.checked_copy_products) === 1).map(b => b.id_branch_office)
                        onSubmitProduct({ data })
                    }} textButton="Replicar">
                        <Select
                            text="Sucursal Origen"
                            options={rowsBranchOffice.map(b => {
                                return {
                                    label: b.name_branch_office,
                                    value: b.id_branch_office,
                                    id_type_money: b.id_type_money
                                }
                            })}
                            value={branchOfficeOriginProduct}
                            onChange={e => {
                                setRowProduct({ ...rowProduct, p_id_branch_office_origin: e.value, p_id_type_money_origin: e.id_type_money })
                                setRowsBrachOffice(rowsBranchOffice.map(b => {
                                    b.checked_copy_products = 0
                                    return b
                                }))
                            }}
                            classNameParent="col-12 mb-2"
                        />
                        {rowProduct.p_id_type_money_origin && <>
                            <div className="col-12 mb-2 mt-2">
                                Seleccionar Sucursales a replicar
                            </div>
                            <CheckBox
                                id={'checked_1_copy_products'}
                                text={'Todos'}
                                onChange={e => {
                                    setRowsBrachOffice(rowsBranchOffice.map(b => {
                                        if (parseInt(b.id_type_money) === parseInt(rowProduct.p_id_type_money_origin) && parseInt(b.id_branch_office) !== parseInt(rowProduct.p_id_branch_office_origin)) {
                                            b.checked_copy_products = e.currentTarget.checked ? 1 : 0
                                        }

                                        return b
                                    }))
                                }}
                            />
                            {React.Children.toArray(rowsBranchOffice.filter(b => {
                                return parseInt(b.id_type_money) === parseInt(rowProduct.p_id_type_money_origin) && parseInt(b.id_branch_office) !== parseInt(rowProduct.p_id_branch_office_origin)
                            }).map((b) => {
                                return <CheckBox
                                    id={'chk-1-2-products-' + b.id_branch_office}
                                    checked={parseInt(b.checked_copy_products) === 1}
                                    text={b.name_branch_office}
                                    onChange={e => {
                                        setRowsBrachOffice(rowsBranchOffice.map((b2) => {
                                            if (parseInt(b.id_branch_office) === parseInt(b2.id_branch_office)) {
                                                b2.checked_copy_products = e.currentTarget.checked ? 1 : 0
                                            }
                                            return b2
                                        }))
                                    }}
                                />
                            }))}
                        </>}
                    </FormCustom>
                </ReactPlaceholder>
            </Card.Body>
        </Card>
        <Card className="mb-4">
            <Card.Header>
                Replicar precios de productos a otro almacén
            </Card.Header>
            <Card.Body>
                <ReactPlaceholder ready={ready} showLoadingAnimation rows={5}>
                    <FormCustom loadSubmit={loadSubmitProductPrice} dataSubmit={rowProductPrice} onSubmit={({ data }) => {
                        if (!rowProductPrice.p_id_branch_office_origin) {
                            Helpers.toast.construct({ response: 'warning', message: 'Seleccionar sucursal origen', toast })
                            return
                        }

                        if (!rowsBranchOffice.find(b => parseInt(b.checked_copy_products_price) === 1)) {
                            Helpers.toast.construct({ response: 'warning', message: 'Seleccionar sucursales a replicar', toast })
                            return
                        }

                        data.p_branch_office_destination = rowsBranchOffice.filter(b => parseInt(b.checked_copy_products_price) === 1).map(b => b.id_branch_office)
                        onSubmitProductPrice({ data })
                    }} textButton="Replicar">
                        <Select
                            text="Sucursal Origen"
                            options={rowsBranchOffice.map(b => {
                                return {
                                    label: b.name_branch_office,
                                    value: b.id_branch_office,
                                    id_type_money: b.id_type_money
                                }
                            })}
                            value={branchOfficeOriginProduct}
                            onChange={e => {
                                setRowProductPrice({ ...rowProductPrice, p_id_branch_office_origin: e.value, p_id_type_money_origin: e.id_type_money })
                                setRowsBrachOffice(rowsBranchOffice.map(b => {
                                    b.checked_copy_products_price = 0
                                    return b
                                }))
                            }}
                            classNameParent="col-12 mb-2"
                        />
                        {rowProductPrice.p_id_type_money_origin && <>
                            <div className="col-12 mb-2 mt-2">
                                Seleccionar Sucursales a replicar
                            </div>
                            <CheckBox
                                id={'checked_2_copy_products_price'}
                                text={'Todos'}
                                onChange={e => {
                                    setRowsBrachOffice(rowsBranchOffice.map(b => {
                                        if (parseInt(b.id_type_money) === parseInt(rowProductPrice.p_id_type_money_origin) && parseInt(b.id_branch_office) !== parseInt(rowProductPrice.p_id_branch_office_origin)) {
                                            b.checked_copy_products_price = e.currentTarget.checked ? 1 : 0
                                        }

                                        return b
                                    }))
                                }}
                            />
                            {React.Children.toArray(rowsBranchOffice.filter(b => {
                                return parseInt(b.id_type_money) === parseInt(rowProductPrice.p_id_type_money_origin) && parseInt(b.id_branch_office) !== parseInt(rowProductPrice.p_id_branch_office_origin)
                            }).map((b) => {
                                return <CheckBox
                                    id={'chk-2-2-products-' + b.id_branch_office}
                                    checked={parseInt(b.checked_copy_products_price) === 1}
                                    text={b.name_branch_office}
                                    onChange={e => {
                                        setRowsBrachOffice(rowsBranchOffice.map((b2) => {
                                            if (parseInt(b.id_branch_office) === parseInt(b2.id_branch_office)) {
                                                b2.checked_copy_products_price = e.currentTarget.checked ? 1 : 0
                                            }
                                            return b2
                                        }))
                                    }}
                                />
                            }))}
                        </>}
                    </FormCustom>
                </ReactPlaceholder>
            </Card.Body>
        </Card>
    </>
}

export default CopyProducts