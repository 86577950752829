import React from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import permissions from './Components/Layout/Resources/Example.json'
import AppLayout from './Views/Layout/Layout'
import Login from './Components/Auth/Login/Login'
import NotFound from './Components/PageError/NotFound/NotFound'
import Helpers from './Components/Helpers/Helpers'
import TypeMoney from './Views/TypeMoney/TypeMoney'
import Country from './Views/Country/Country'
import BranchOffice from './Views/BranchOffice/BranchOffice'
import TypeRound from './Views/TypeRound/TypeRound'
import Tax from './Views/Tax/Tax'
import Bank from './Views/Bank/Bank'
import TypeFormatPay from './Views/TypeFormatPay/TypeFormatPay'
import Language from './Views/Language/Language'
import Customers from './Views/Customers/Customers'
import TypePrice from './Views/TypePrice/TypePrice'
import TypeFormatPrint from './Views/TypeFormatPrint/TypeFormatPrint'
import Clasification from './Views/Clasification/Clasification'
import Product from './Views/Product/Product'
import Exempt from './Views/Exempt/Exempt'
/* import Component from './Views/Component/Component' */
import TypeDocument from './Views/TypeDocument/TypeDocument'
import Document from './Views/Document/Document'
import Inventory from './Views/Inventory/Inventory'
import Sale from './Views/Sale/Sale'
import Dashboard from './Views/Dashboard/Dashboard'
import Period from './Views/Period/Period'
import Ecommerce from './Views/Ecommerce/Ecommerce'
import Cart from './Views/Ecommerce/Cart'
import PaymentSuccess from './Views/Payment/PaymentSuccess'
import { useSelector } from 'react-redux'
import PersonalInformation from './Views/PersonaInformation/PersonalInformation'
import Red from './Views/Red/Red'
import Commissions from './Views/Red/Commissions'
import HistorySale from './Views/Report/HistorySale'
import Profile from './Views/Profile/Profile'
import Worker from './Views/Worker/Worker'
import Exchange from './Views/Exchange/Exchange'
import Promo from './Views/Promo/Promo'
import CopyProducts from './Views/BranchOffice/CopyProducts'
import ImagesSystem from './Views/Images/ImagesSystem'
import ClosePeriod from './Views/Period/ClosePeriod'
import SaleTotalBranchOffice from './Views/Report/SaleTotalBranchOffice'
import SaleProductsQtyPrice from './Views/Report/SaleProductsQtyPrice'
import CustomersNew from './Views/Report/CustomersNew'
import ReporteRed from './Views/Report/ReporteRed'
import ReporteSaleCustomers from './Views/Report/ReporteSaleCustomers'
import File from './Views/File/File'
import FilePublic from './Views/File/FilePublic'
import BreakBoxUser from './Views/Report/BreakBoxUser'
import BreakBoxBranchOffice from './Views/Report/BreakBoxBranchOffice'
import Limit from './Views/Limit/Limit'
import CommisonsPeriod from './Views/Report/CommisonsPeriod'
import CustomersAddress from './Views/CustomersAddress/CustomersAddress'
import PointPeriod from './Views/Report/PointPeriod'
import HistoryCommisions from './Views/Report/HistoryCommisions'
import Dispatch from './Views/Dispatch/Dispatch'
import CustomersPoint from './Views/Report/CustomersPoint'
import CustomersList from './Views/Report/CustomersList'
import SaveMyCustomers from './Views/Customers/SaveMyCustomers'
import Kardex from './Views/Report/Kardex'
import ReporteInventario from './Views/Report/ReporteInventario'

const Routes = ({ type }) => {

  const store = useSelector(store => store)

  const hasPermission = (page) => {
    let permissions = store.session.permissions
    
    let has = false
    
    permissions.forEach(tpm => {
      if (has) {
        return false
      }

      tpm.childrens.forEach(tp => {
        if (has) {
          return false
        }

        tp.childrens.forEach(tp => {
          if (has) {
            return false
          }

          if (tp.a_href === page) {
            has = true
          }
        })
      })
    })

    return has
  }
  
  if (type === 'auth') {
    return <BrowserRouter>
    <Switch>
      <Route exact path={Helpers.config.convertUrl({ url: '/login/:type?' })}>
        <Login />
      </Route>
      <Route exact path={Helpers.config.convertUrl({ url: '/' })}>
        <Login />
      </Route>
      <Route>
        <Redirect to={Helpers.config.convertUrl({ url: '/login/clientes' })} />
      </Route>
    </Switch>
  </BrowserRouter>
  }
  
  return <BrowserRouter>
    <Switch>
      {hasPermission('monedas') ? <Route path={Helpers.config.convertUrl({ url: '/monedas' })} exact>
        <AppLayout title="Listado De Monedas" permissions={permissions} page="monedas">
          <TypeMoney nameSingle={Helpers.string.toCamelCase('Moneda')} namePlural={Helpers.string.toCamelCase('Monedas')} separator={'La'.toLowerCase()} />
        </AppLayout>
      </Route> : ''}
      {hasPermission('paises') ? <Route path={Helpers.config.convertUrl({ url: '/paises' })} exact>
        <AppLayout title="Listado De Paises" permissions={permissions} page="paises">
          <Country nameSingle={Helpers.string.toCamelCase('País')} namePlural={Helpers.string.toCamelCase('Paises')} separator={'El'.toLowerCase()} />
        </AppLayout>
      </Route> : ''}
      {hasPermission('sucursales') ? <Route path={Helpers.config.convertUrl({ url: '/sucursales' })} exact>
        <AppLayout title="Listado De Sucursales" permissions={permissions} page="sucursales">
          <BranchOffice nameSingle={Helpers.string.toCamelCase('Sucursal')} namePlural={Helpers.string.toCamelCase('Sucursales')} separator={'La'.toLowerCase()} />
        </AppLayout>
      </Route> : ''}
      {hasPermission('redondeos') ? <Route path={Helpers.config.convertUrl({ url: '/redondeos' })} exact>
        <AppLayout title="Listado De Redondeos" permissions={permissions} page="redondeos">
          <TypeRound nameSingle={Helpers.string.toCamelCase('Redondeo')} namePlural={Helpers.string.toCamelCase('Redondeos')} separator={'El'.toLowerCase()} />
        </AppLayout>
      </Route> : ''}
      {hasPermission('impuestos') ? <Route path={Helpers.config.convertUrl({ url: '/impuestos' })} exact>
        <AppLayout title="Listado De Impuestos" permissions={permissions} page="impuestos">
          <Tax nameSingle={Helpers.string.toCamelCase('Impuesto')} namePlural={Helpers.string.toCamelCase('Impuestos')} separator={'el'.toLowerCase()} />
        </AppLayout>
      </Route> : ''}
      {hasPermission('bancos') ? <Route path={Helpers.config.convertUrl({ url: '/bancos' })} exact>
        <AppLayout title="Listado De Bancos" permissions={permissions} page="bancos">
          <Bank nameSingle={Helpers.string.toCamelCase('Bancos')} namePlural={Helpers.string.toCamelCase('Banco')} separator={'el'.toLowerCase()} />
        </AppLayout>
      </Route> : ''}
      {hasPermission('formatos-de-pago') ? <Route path={Helpers.config.convertUrl({ url: '/formatos-de-pago' })} exact>
        <AppLayout title="Listado De Formatos De Pago" permissions={permissions} page="formatos-de-pago">
          <TypeFormatPay nameSingle={Helpers.string.toCamelCase('Formato de Pago')} namePlural={Helpers.string.toCamelCase('Formatos de Pago')} separator={'el'.toLowerCase()} />
        </AppLayout>
      </Route> : ''}
      {hasPermission('clientes') ? <Route path={Helpers.config.convertUrl({ url: '/clientes' })} exact>
        <AppLayout title="Listado De Clientes" permissions={permissions} page="clientes">
          <Customers nameSingle={Helpers.string.toCamelCase('Cliente')} namePlural={Helpers.string.toCamelCase('Clientes')} separator={'el'.toLowerCase()} />
        </AppLayout>
      </Route> : ''}
      {hasPermission('idiomas') ? <Route path={Helpers.config.convertUrl({ url: '/idiomas' })} exact>
        <AppLayout title="Listado De Idiomas" permissions={permissions} page="idiomas">
          <Language nameSingle={Helpers.string.toCamelCase('Idioma')} namePlural={Helpers.string.toCamelCase('Idiomas')} separator={'el'.toLowerCase()} />
        </AppLayout>
      </Route> : ''}
      {hasPermission('tipos-de-precio') ? <Route path={Helpers.config.convertUrl({ url: '/tipos-de-precio' })} exact>
        <AppLayout title="Listado De Precios" permissions={permissions} page="tipos-de-precio">
          <TypePrice nameSingle={Helpers.string.toCamelCase('Tipo De Precio')} namePlural={Helpers.string.toCamelCase('Tipos De Precio')} separator={'el'.toLowerCase()} />
        </AppLayout>
      </Route> : ''}
      {hasPermission('formatos-de-ticket') ? <Route path={Helpers.config.convertUrl({ url: '/formatos-de-ticket' })} exact>
        <AppLayout title="Listado De Formatos De Ticket" permissions={permissions} page="formatos-de-ticket">
          <TypeFormatPrint nameSingle={Helpers.string.toCamelCase('Formato De Ticket')} namePlural={Helpers.string.toCamelCase('Formatos De Ticket')} separator={'el'.toLowerCase()} />
        </AppLayout>
      </Route> : ''}
      {hasPermission('clasificaciones') ? <Route path={Helpers.config.convertUrl({ url: '/clasificaciones' })} exact>
        <AppLayout title="Listado De Clasificaciones" permissions={permissions} page="clasificaciones">
          <Clasification nameSingle={Helpers.string.toCamelCase('Clasificación')} namePlural={Helpers.string.toCamelCase('Clasificaciones')} separator={'la'.toLowerCase()} />
        </AppLayout>
      </Route> : ''}
      {hasPermission('productos') ? <Route path={Helpers.config.convertUrl({ url: '/productos' })} exact>
        <AppLayout title="Listado De Productos" permissions={permissions} page="productos">
          <Product nameSingle={Helpers.string.toCamelCase('Producto')} namePlural={Helpers.string.toCamelCase('Productos')} separator={'el'.toLowerCase()} />
        </AppLayout>
      </Route> : ''}
      {hasPermission('excentos') ? <Route path={Helpers.config.convertUrl({ url: '/excentos' })} exact>
        <AppLayout title="Listado De Excentos" permissions={permissions} page="excentos">
          <Exempt nameSingle={Helpers.string.toCamelCase('Excento')} namePlural={Helpers.string.toCamelCase('Excentos')} separator={'el'.toLowerCase()} />
        </AppLayout>
      </Route> : ''}
      {/* <Route path={Helpers.config.convertUrl({ url: '/componentes' })} exact>
        <AppLayout title="Listado De Componentes" permissions={permissions} page="componentes">
          <Component nameSingle={Helpers.string.toCamelCase('Componente')} namePlural={Helpers.string.toCamelCase('Componentes')} separator={'el'.toLowerCase()} />
        </AppLayout>
      </Route> */}
      {hasPermission('tipos-de-documento') ? <Route path={Helpers.config.convertUrl({ url: '/tipos-de-documento' })} exact>
        <AppLayout title="Listado De Tipos De Documento" permissions={permissions} page="tipos-de-documento">
          <TypeDocument nameSingle={Helpers.string.toCamelCase('Tipo De Documento')} namePlural={Helpers.string.toCamelCase('Tipos De Documento')} separator={'el'.toLowerCase()} />
        </AppLayout>
      </Route> : ''}
      {hasPermission('documentos') ? <Route path={Helpers.config.convertUrl({ url: '/documentos' })} exact>
        <AppLayout title="Listado De Documentos" permissions={permissions} page="documentos">
          <Document nameSingle={Helpers.string.toCamelCase('Documento')} namePlural={Helpers.string.toCamelCase('Documentos')} separator={'el'.toLowerCase()} />
        </AppLayout>
      </Route> : ''}
      {hasPermission('inventario') ? <Route path={Helpers.config.convertUrl({ url: '/inventario' })} exact>
        <AppLayout title="Listado De Inventarios" permissions={permissions} page="inventario">
          <Inventory nameSingle={Helpers.string.toCamelCase('Inventario')} namePlural={Helpers.string.toCamelCase('Inventarios')} separator={'el'.toLowerCase()} />
        </AppLayout>
      </Route> : ''}
      {hasPermission('ventas') ? <Route path={Helpers.config.convertUrl({ url: '/ventas' })} exact>
        <AppLayout title="Listado De Ventas" permissions={permissions} page="ventas">
          <Sale nameSingle={Helpers.string.toCamelCase('Venta')} namePlural={Helpers.string.toCamelCase('Ventas')} separator={'el'.toLowerCase()} />
        </AppLayout>
      </Route> : ''}
      {hasPermission('dashboard') ? <Route path={Helpers.config.convertUrl({ url: '/dashboard' })} exact>
        <AppLayout title="Dashboard" permissions={permissions} page="dashboard">
          <Dashboard />
        </AppLayout>
      </Route> : ''}
      {hasPermission('periodo') ? <Route path={Helpers.config.convertUrl({ url: '/periodo' })} exact>
        <AppLayout title="Listado De Periodos" permissions={permissions} page="periodo">
          <Period nameSingle={Helpers.string.toCamelCase('Periodo')} namePlural={Helpers.string.toCamelCase('Periodos')} separator={'el'.toLowerCase()} />
        </AppLayout>
      </Route> : ''}
      {hasPermission('ecommerce') /* && parseInt(store.session.username) === 1 */ ? <Route path={Helpers.config.convertUrl({ url: '/ecommerce' })} exact>
        <AppLayout hideTop title="Tienda En Línea" permissions={permissions} page="ecommerce">
          <Ecommerce />
        </AppLayout>
      </Route> : ''}
      {hasPermission('ecommerce') /* && parseInt(store.session.username) === 1 */? <Route path={Helpers.config.convertUrl({ url: '/carrito/:type?' })} exact>
        <AppLayout hideTop title="Carrito" permissions={permissions} page="ecommerce">
          <Cart />
        </AppLayout>
      </Route> : ''}
      {hasPermission('ecommerce') /* && parseInt(store.session.username) === 1 */ ? <Route path={Helpers.config.convertUrl({ url: '/pago-realizado' })} exact>
        <AppLayout hideTop title="Carrito" permissions={permissions} page="ecommerce">
          <PaymentSuccess />
        </AppLayout>
      </Route> : ''}
      {hasPermission('informacion-personal') ? <Route path={Helpers.config.convertUrl({ url: '/informacion-personal' })} exact>
        <AppLayout hideTop title="Información Personal" permissions={permissions} page="informacion-personal">
          <PersonalInformation />
        </AppLayout>
      </Route> : ''}
      {hasPermission('descendia-de-red') ? <Route path={Helpers.config.convertUrl({ url: '/descendia-de-red' })} exact>
        <AppLayout hideTop title="Descendencia de Red" permissions={permissions} page="descendia-de-red">
          <Red />
        </AppLayout>
      </Route> : ''}
      {hasPermission('comisiones') ? <Route path={Helpers.config.convertUrl({ url: '/comisiones' })} exact>
        <AppLayout hideTop title="Comisiones" permissions={permissions} page="comisiones">
          <Commissions />
        </AppLayout>
      </Route> : ''}
      {hasPermission('historial-compras') ? <Route path={Helpers.config.convertUrl({ url: '/historial-compras' })} exact>
        <AppLayout hideTop title="Historial de Compras" permissions={permissions} page="historial-compras">
          <HistorySale />
        </AppLayout>
      </Route> : ''}
      {hasPermission('limites') ? <Route path={Helpers.config.convertUrl({ url: '/limites' })} exact>
        <AppLayout hideTop title="Límites" permissions={permissions} page="limites">
          <Limit nameSingle={Helpers.string.toCamelCase('Límite')} namePlural={Helpers.string.toCamelCase('Límites')} separator={'el'.toLowerCase()} />
        </AppLayout>
      </Route> : ''}
      {hasPermission('comisiones-periodo') ? <Route path={Helpers.config.convertUrl({ url: '/comisiones-periodo' })} exact>
        <AppLayout hideTop title="Comisiones por periódo" permissions={permissions} page="comisiones-periodo">
          <CommisonsPeriod />
        </AppLayout>
      </Route> : ''}
      {hasPermission('historial-comisiones') ? <Route path={Helpers.config.convertUrl({ url: '/historial-comisiones' })} exact>
        <AppLayout hideTop title="Historial de Comisiones" permissions={permissions} page="historial-comisiones">
          <HistoryCommisions />
        </AppLayout>
      </Route> : ''}
      {hasPermission('puntos-periodo') ? <Route path={Helpers.config.convertUrl({ url: '/puntos-periodo' })} exact>
        <AppLayout hideTop title="Puntos por periódo" permissions={permissions} page="puntos-periodo">
          <PointPeriod />
        </AppLayout>
      </Route> : ''}
      {hasPermission('perfiles') ? <Route path={Helpers.config.convertUrl({ url: '/perfiles' })} exact>
        <AppLayout hideTop title="Perfiles" permissions={permissions} page="perfiles">
          <Profile namePlural="Perfiles" nameSingle="Perfil" separator="el" />
        </AppLayout>
      </Route> : ''}
      {hasPermission('trabajadores') ? <Route path={Helpers.config.convertUrl({ url: '/trabajadores' })} exact>
        <AppLayout hideTop title="Trabajadores" permissions={permissions} page="trabajadores">
          <Worker namePlural="Trabajadores" nameSingle="Trabajador" separator="el" />
        </AppLayout>
      </Route> : ''}
      {hasPermission('paridades') ? <Route path={Helpers.config.convertUrl({ url: '/paridades' })} exact>
        <AppLayout hideTop title="Paridades" permissions={permissions} page="paridades">
          <Exchange namePlural="Paridades" nameSingle="Paridad" separator="la" />
        </AppLayout>
      </Route> : ''}
      {hasPermission('promociones') ? <Route path={Helpers.config.convertUrl({ url: '/promociones' })} exact>
        <AppLayout hideTop title="Promociones" permissions={permissions} page="promociones">
          <Promo namePlural="Promociones" nameSingle="Promoción" separator="la" />
        </AppLayout>
      </Route> : ''}
      {hasPermission('copia-productos') ? <Route path={Helpers.config.convertUrl({ url: '/copia-productos' })} exact>
        <AppLayout hideTop title="Copia de Productos" permissions={permissions} page="copia-productos">
          <CopyProducts />
        </AppLayout>
      </Route> : ''}
      {hasPermission('imagenes-sistema') ? <Route path={Helpers.config.convertUrl({ url: '/imagenes-sistema' })} exact>
        <AppLayout hideTop title="Imagenes del Sistema" permissions={permissions} page="imagenes-sistema">
          <ImagesSystem />
        </AppLayout>
      </Route> : ''}
      {hasPermission('cerrar-periodo') ? <Route path={Helpers.config.convertUrl({ url: '/cerrar-periodo' })} exact>
        <AppLayout hideTop title="Cerrar Periodo" permissions={permissions} page="cerrar-periodo">
          <ClosePeriod />
        </AppLayout>
      </Route> : ''}
      {hasPermission('ventas-totales-sucursal') ? <Route path={Helpers.config.convertUrl({ url: '/ventas-totales-sucursal' })} exact>
        <AppLayout hideTop title="Ventas Totales por Sucursal" permissions={permissions} page="ventas-totales-sucursal">
          <SaleTotalBranchOffice />
        </AppLayout>
      </Route> : ''}
      {hasPermission('ventas-producto-cantidad-precio') ? <Route path={Helpers.config.convertUrl({ url: '/ventas-producto-cantidad-precio' })} exact>
        <AppLayout hideTop title="Ventas Producto Cantidad y Precio" permissions={permissions} page="ventas-producto-cantidad-precio">
          <SaleProductsQtyPrice />
        </AppLayout>
      </Route> : ''}
      {hasPermission('clientes-nuevos') ? <Route path={Helpers.config.convertUrl({ url: '/clientes-nuevos' })} exact>
        <AppLayout hideTop title="Clientes Nuevos" permissions={permissions} page="clientes-nuevos">
          <CustomersNew />
        </AppLayout>
      </Route> : ''}
      {hasPermission('descendencia-de-red') ? <Route path={Helpers.config.convertUrl({ url: '/descendencia-de-red' })} exact>
        <AppLayout hideTop title="Descendencia de Red" permissions={permissions} page="descendencia-de-red">
          <ReporteRed />
        </AppLayout>
      </Route> : ''}
      {hasPermission('ventas-por-cliente') ? <Route path={Helpers.config.convertUrl({ url: '/ventas-por-cliente' })} exact>
        <AppLayout hideTop title="Ventas por Cliente" permissions={permissions} page="ventas-por-cliente">
          <ReporteSaleCustomers />
        </AppLayout>
      </Route> : ''}
      {hasPermission('configurar-archivos') ? <Route path={Helpers.config.convertUrl({ url: '/configurar-archivos' })} exact>
        <AppLayout hideTop title="Configurar Archivos" permissions={permissions} page="configurar-archivos">
          <File namePlural="Archivos" nameSingle="Archivo" separator="el" />
        </AppLayout>
      </Route> : ''}
      {hasPermission('archivos') ? <Route path={Helpers.config.convertUrl({ url: '/archivos' })} exact>
        <AppLayout hideTop title="Archivos" permissions={permissions} page="archivos">
          <FilePublic />
        </AppLayout>
      </Route> : ''}
      {hasPermission('corte-caja-usuario') ? <Route path={Helpers.config.convertUrl({ url: '/corte-caja-usuario' })} exact>
        <AppLayout hideTop title="Corte de Caja por Usuario" permissions={permissions} page="corte-caja-usuario">
          <BreakBoxUser />
        </AppLayout>
      </Route> : ''}
      {hasPermission('corte-caja-sucursal') ? <Route path={Helpers.config.convertUrl({ url: '/corte-caja-sucursal' })} exact>
        <AppLayout hideTop title="Corte de Caja por Sucursal" permissions={permissions} page="corte-caja-sucursal">
          <BreakBoxBranchOffice />
        </AppLayout>
      </Route> : ''}
      {hasPermission('direcciones-envio') ? <Route path={Helpers.config.convertUrl({ url: '/direcciones-envio' })} exact>
        <AppLayout hideTop title="Direcciones de Envío" permissions={permissions} page="direcciones-envio">
          <CustomersAddress namePlural="Direcciones de Envío" nameSingle="Dirección de Envío" separator="la" />
        </AppLayout>
      </Route> : ''}
      {hasPermission('tipos-de-despacho') ? <Route path={Helpers.config.convertUrl({ url: '/tipos-de-despacho' })} exact>
        <AppLayout hideTop title="Tipos de Despacho" permissions={permissions} page="tipos-de-despacho">
          <Dispatch namePlural="Tipos de Despacho" nameSingle="Tipo de Despacho" separator="el" />
        </AppLayout>
      </Route> : ''}
      {hasPermission('puntos-de-clientes') ? <Route path={Helpers.config.convertUrl({ url: '/puntos-de-clientes' })} exact>
        <AppLayout hideTop title="Reporte de Puntos de Clientes" permissions={permissions} page="puntos-de-clientes">
          <CustomersPoint />
        </AppLayout>
      </Route> : ''}
      {hasPermission('listado-clientes') ? <Route path={Helpers.config.convertUrl({ url: '/listado-clientes' })} exact>
        <AppLayout hideTop title="Listado de Clientes" permissions={permissions} page="listado-clientes">
          <CustomersList />
        </AppLayout>
      </Route> : ''}
      {hasPermission('registrar-cliente') ? <Route path={Helpers.config.convertUrl({ url: '/registrar-cliente' })} exact>
        <AppLayout hideTop title="Registrar Cliente" permissions={permissions} page="registrar-cliente">
          <SaveMyCustomers />
        </AppLayout>
      </Route> : ''}
      {hasPermission('kardex') ? <Route path={Helpers.config.convertUrl({ url: '/kardex' })} exact>
        <AppLayout hideTop title="Kardex" permissions={permissions} page="kardex">
          <Kardex />
        </AppLayout>
      </Route> : ''}
      {hasPermission('reporte-inventario') ? <Route path={Helpers.config.convertUrl({ url: '/reporte-inventario' })} exact>
        <AppLayout hideTop title="Reporte Inventario" permissions={permissions} page="reporte-inventario">
          <ReporteInventario />
        </AppLayout>
      </Route> : ''}
      <Route path={Helpers.config.convertUrl({ url: '/login' })} exact>
        <Redirect to={Helpers.config.convertUrl({ url: '/' + store.session.main_profile })} />
      </Route>
      <Route path={Helpers.config.convertUrl({ url: '/' })} exact>
        <Redirect to={Helpers.config.convertUrl({ url: '/' + store.session.main_profile })} />
      </Route>
      <Route>
        <AppLayout title="No existe la página" permissions={permissions} showBread={false}>
          <NotFound backUrl="/" imageNotFound={Helpers.config.resourcesUrl({ url: '/images/dashboard/not-found.png' })} />
        </AppLayout>
      </Route>
    </Switch>
  </BrowserRouter>
}

Routes.defaultProps = {
  type: 'session'
}

export default Routes