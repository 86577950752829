import axios from 'axios'

const Helpers = {}

Helpers.config = {
  folder_path: process.env.NODE_ENV === 'production' ? window.pathFolder : process.env.REACT_APP_FOLDER_PATH,
  isProduction: process.env.NODE_ENV === 'production',
  api: {
    url: process.env.NODE_ENV === 'production' ? window.pathUrl : process.env.REACT_APP_API_URL,
    resources: process.env.NODE_ENV === 'production' ? window.pathUrlAssets : process.env.REACT_APP_RESOURCES_URL
  },
  company: {
    name: process.env.NODE_ENV === 'production' ? window.pathCompanyName : process.env.REACT_APP_COMPANY_NAME
  },
  apiUrl: ({ url = ''  } = {}) => Helpers.config.api.url + url,
  convertUrl: ({ url = '' } = {}) => Helpers.config.folder_path + url,
  resourcesUrl: ({ url = ''  } = {}) => Helpers.config.api.resources + url,
  pointMinRange: 1500,
  percentageIva: 0.16,
  systemType: process.env.NODE_ENV === 'production' ? (window.systemType ? window.systemType : 'CUSTOMERS') : 'DEV',
  isDeveloperWeb: _ => {
    return Helpers.config.systemType === 'DEVELOPERPERU'
  },
  isCustomers: _ => {
    return Helpers.config.systemType === 'CUSTOMERS'
  },
  verifyToken: _ => {
    if (Helpers.config.isProduction) {
      if (Helpers.config.isCustomers()) {
        if (Helpers.date.get({ format: '%Y-%m-%d' }) >= atob('MjAyMy0xMi0wMQ==')) { // 2022-12-01
          return false
        }
      } else {
        if (!Helpers.config.isDeveloperWeb()) {
          return false
        }
      }
    }

    return true
  },
  devInformation: _ => {
    console.log('%cCARLOS ESTHEFANO HUAMAN MARCOS', 'color: RED; font-size: 20px; font-weight: bold');
    console.log('%cDESARROLLADOR DE SOFTWARE', 'color: RED; font-size: 15px; font-weight: bold');
    console.log('%cPERU', 'color: RED; font-size: 15px; font-weight: bold');
    console.log('%cWHATSAPP +51 989998352', 'color: RED; font-size: 15px; font-weight: bold');
    console.log('%cEMAIL carhuaman1995@gmail.com', 'color: RED; font-size: 15px; font-weight: bold');
  }
}

Helpers.axios = ({ method = 'GET', url = '', data = {}, hasFile = false }) => {
  let urlReq = method === 'GET' ? url + '?jwt=' + Helpers.localStorage.getJWT() : url
  let bodyReq
  if  (method === 'POST') {
    if (hasFile) {
      bodyReq = data
      bodyReq.append('jwt', Helpers.localStorage.getJWT())
    } else {
      bodyReq = { ...data }
      bodyReq.jwt = Helpers.localStorage.getJWT()
    }
  }
  let config = {
    method,
    url: Helpers.config.api.url + urlReq,
    data: bodyReq
  }

  if (hasFile) {
    config.headers = { 'content-type': 'multipart/form-data' }
  }
  
  return new Promise((resolve, reject) => {
    axios(config).then(res => {
      return res.data
    }).then(res => {
      /* if (!Helpers.config.isProduction) {
        console.log(res)
      } */
      if (res.message === 'error_session_api') {
        localStorage.clear()
        window.location.href = Helpers.config.folder_path + '/'
        return
      }
      if (res.hasOwnProperty('data')) {
        resolve(res)
      } else {
        reject({
          response: 'danger',
          message: 'No se ha podido realizar la conexión al servidor. Contacte con el área de IT.'
        })
      }
    }).catch(req => {
      /* if (!Helpers.config.isProduction) {
        console.log(req)
      } */
      reject({
        response: 'danger',
        message: 'No se ha podido realizar la conexión al servidor. Contacte con el área de IT.'
      })
    })
  })
}

Helpers.promise = {}
Helpers.promise.catch = ({ req = {}, toast = {}}) => {
  /* if (!Helpers.config.isProduction) {
    console.log(req)
  } */
  if (req.response) {
    Helpers.toast.construct({response: req.response, message: req.message, toast})
  } else {
    Helpers.toast.construct({response: 'error', message: 'No se ha podido realizar la conexión al servidor. Contacte con el área de IT.', toast})
  }
}

Helpers.toast = {
  options: () => {
    return {
      autoClose: 5000,
      hideProgressBar: false,
      pauseOnHover: true,
      progress: 0.2
    }
  },
  construct: ({ response = '', message = '', toast = {} }) => {
    if (response === 'success') {
      toast.success(message, '', Helpers.toast.options())
    } else if (response === 'info') {
      toast.info(message, '', Helpers.toast.options())
    } else if (response === 'warning') {
      toast.warning(message, '', Helpers.toast.options())
    } else {
      toast.error(message, '', Helpers.toast.options())
    }
  }
}

Helpers.localStorage = {
  name: 'session_react_botnatura',
  getSession: () => JSON.parse(localStorage.getItem(Helpers.localStorage.name)),
  exists: () => localStorage.getItem(Helpers.localStorage.name),
  existsJWT: () => {
    if (Helpers.localStorage.exists()) {
      if (Helpers.localStorage.getSession().jwt) {
        return true
      }
    }
    return false
  },
  setSession: (session) => localStorage.setItem(Helpers.localStorage.name, JSON.stringify(session)),
  getJWT: () => Helpers.localStorage.existsJWT() ? Helpers.localStorage.getSession().jwt : ''
}

Helpers.date = {
  get: ({ format = '%Y-%m-%d', addDays = 0 } = {}) => {
    let date = new Date()
    if (addDays !== 0) {
      date.setDate(date.getDate() + addDays)
    }

    let d = {
      year: date.getFullYear(),
      month: date.getMonth(),
      day: date.getDate(),
      hours: date.getHours(),
      minutes: date.getMinutes()
    }
    let dateTex = format.replace('%Y', d.year)
    .replace('%m', (d.month + 1 < 10 ? '0' : '') + (d.month + 1))
    .replace('%d', (d.day < 10 ? '0' : '') + d.day)
    .replace('%H', (d.hours < 10 ? '0' : '') + d.hours)
    .replace('%i', (d.minutes < 10 ? '0' : '') + d.minutes)

    return dateTex
  }
}

Helpers.number = {
  float: {
    format: ({ number } = {}) => {
      return parseFloat(number).toLocaleString('es-MX', { minimumFractionDigits: 2 })
    }
  }
}

Helpers.string = {
  toCamelCase: str => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
}

Helpers.getActions = ({ permissions, module }) => {
  let actions = {}
  permissions.forEach(tpm => {
      tpm.childrens.forEach(tp => {
          tp.childrens.forEach(t => {
              if (t.a_href === module) {
                  t.actions.forEach(a => {
                      actions[a.field_action] = a
                  })
              }
          })
      })
  })
  return actions
}

export default Helpers