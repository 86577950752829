import React, { useState, useMemo, useCallback } from 'react'
import { useEffect } from 'react'
import ReactPlaceholder from 'react-placeholder/lib'
import Controllers from '../../Api/Controllers'
import { Input, FormCustom, SwitchToggle, Select, CheckBox } from '../../Components/Form/Form'
import Helpers from '../../Components/Helpers/Helpers'
import { toast } from 'react-toastify'

const ProfileForm = ({ data, onSubmit, viewSubmit, loadSubmit }) => {
    const [row, setRow] = useState({
        p_id_profile: data.id_profile,
        p_description_profile: data.description_profile,
        p_enabled_profile: data.enabled_profile,
        p_main_profile: data.main_profile
    })

    const [ready, setReady] = useState(false)
    const [permissions, setPermissions] = useState([])
    const [checkedAll, setCheckedAll] = useState(false)

    useEffect(() => {
        Controllers.tags_items.get_tags_items_profile({
            p_id_profile: data.id_profile
        }).then(res => {
            setPermissions(res.data)
            setReady(true)
        })
    }, [data.id_profile])

    const handlEnabledTagsItems = ({ e, type }) => {
        let { idTagsItems } = e.currentTarget.dataset
        setPermissions(permissions.map(tpm => {
            tpm.childrens = tpm.childrens.map(tp => {
                tp.childrens = tp.childrens.map(t => {
                    if (type === 'all') {
                        t.enabled = e.currentTarget.checked ? 1 : 0
                    } else if (type === 'main') {
                        if (t.id_tags_items === idTagsItems) {
                            setRow({ ...row, p_main_profile: t.a_href })
                            t.main = 1
                            t.enabled = 1
                        } else {
                            t.main = 0
                        }
                    } else {
                        if (t.id_tags_items === idTagsItems) {
                            t.enabled = parseInt(t.main) === 1 ? 1 : (e.currentTarget.checked ? 1 : 0)
                        }
                    }
                    return t
                })
                return tp
            })
            return tpm
        }))
    }

    const isCheckedAll = () => {
        let qtyTagsItems = 0
        let qtyTagsItemsChecked = 0
        permissions.forEach(tpm => {
            tpm.childrens.forEach(tp => {
                tp.childrens.forEach(t => {
                    qtyTagsItems++
                    if (parseInt(t.enabled) === 1) {
                        qtyTagsItemsChecked++
                    }
                })
            })
        })

        return qtyTagsItems === qtyTagsItemsChecked
    }

    const dataSubmit = useCallback(() => {
        let p_permissions = []

        permissions.forEach(tpm => {
            tpm.childrens.forEach(tp => {
                tp.childrens.forEach(t => {
                    p_permissions.push({
                        p_id_tags_items_parent_master: t.id_tags_items_parent_master,
                        p_id_tags_items_parent: t.id_tags_items_parent,
                        p_id_tags_items: t.id_tags_items,
                        p_enabled: t.enabled,
                        p_main: t.main,
                        p_actions: t.actions
                    })
                })
            })
        })

        return {
            ...row,
            p_permissions
        }
    }, [row, permissions])

    return <ReactPlaceholder ready={ready} showLoadingAnimation rows={15}>
        <FormCustom dataSubmit={dataSubmit()} onSubmit={({ data }) => {
            if (!row.p_main_profile) {
                Helpers.toast.construct({ response: 'warning', message: 'Por favor, seleccionar página principal.', toast })
                return
            }
            onSubmit({ data })
        }} viewSubmit={viewSubmit} loadSubmit={loadSubmit}>
            <Input
                text="Descripción"
                placeholder="Descripción"
                classNameParent="col-12 mt-2 mb-2"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.description_profile}
                onChange={e => setRow({ ...row, p_description_profile: e.currentTarget.value })}
                maxLength={255}
                disabled={!viewSubmit}
            />
            <SwitchToggle
                classNameParent="col-12 mb-2"
                id="profile_state"
                defaultChecked={parseInt(row.p_enabled_profile) === 1}
                onChange={e => setRow({ ...row, p_enabled_profile: e.currentTarget.checked ? 1 : 0 })}
                disabled={!viewSubmit}
            />

            <div className="table-responsive">
                <table className="dt-react table table-sm table-hover table-bordered">
                    <thead>
                        <tr>
                            <th>Menú</th>
                            <th>
                                {viewSubmit ? <CheckBox
                                    id="chk-all-privileges"
                                    onChange={e => handlEnabledTagsItems({ e, type: 'all' })}
                                    checked={checkedAll || isCheckedAll()}
                                /> : ''}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {React.Children.toArray(permissions.map(tpm => {
                            {/* <tr>
                            <td>{tpm.parent.description}</td>
                            <td></td>
                        </tr> */}
                            let trs = [

                            ]

                            tpm.childrens.forEach(tp => {
                                trs.push(<tr>
                                    <td>
                                        <span>{tp.parent.description}</span>
                                    </td>
                                    <td></td>
                                </tr>)


                                tp.childrens.forEach(t => {
                                    trs.push(<tr>
                                        <td>
                                            <span className="ml-3">{t.description}</span>
                                            {parseInt(t.main) === 1 ? <i className="fas fa-check-square ml-2 text-success"></i> : (viewSubmit ? <i className="far fa-check-square ml-2 cur-pointer" style={{
                                                opacity: .4
                                            }} data-id-tags-items={t.id_tags_items} onClick={e => handlEnabledTagsItems({ e, type: 'main' })}></i> : '')}

                                            {t?.actions?.length > 0 ? <div className="ml-5 mt-1 mb-3">
                                                <strong>Acciones</strong>
                                                <div>
                                                    {React.Children.toArray(t.actions.map(a => {
                                                        return <>
                                                            <CheckBox
                                                                text={<div>{a.name_action} <i className={a.icon + ' ml-2 text-' + a.color_action}></i></div>}
                                                                id={'chk-item-action-' + t.id_tags_items + '-' + a.id_action}
                                                                checked={parseInt(a.enabled) === 1}
                                                                onChange={e => {
                                                                    setPermissions(permissions.map(tpm2 => {
                                                                        tpm2.childrens = tpm2.childrens.map(tp2 => {
                                                                            tp2.childrens = tp2.childrens.map(t2 => {
                                                                                if (t2.id_tags_items === t.id_tags_items) {
                                                                                    t2.actions = t2.actions.map(a2 => {
                                                                                        if (parseInt(a2.id_action) === parseInt(a.id_action)) {
                                                                                            a2.enabled = e.currentTarget.checked ? 1 : 0
                                                                                        }

                                                                                        return a2
                                                                                    })
                                                                                }
                                                                                
                                                                                return t2
                                                                            })
                                                                            return tp2
                                                                        })
                                                                        return tpm2
                                                                    }))
                                                                }}
                                                            />
                                                        </>
                                                    }))}
                                                </div>
                                            </div> : ''}
                                        </td>
                                        <td align="center">
                                            {viewSubmit ? <CheckBox
                                                id={'chk-item-' + t.id_tags_items}
                                                checked={parseInt(t.enabled) === 1}
                                                onChange={e => handlEnabledTagsItems({ e, type: 'one' })}
                                                data-id-tags-items={t.id_tags_items}
                                            /> : <i className={parseInt(t.enabled) === 1 ? 'fa fa-check text-success' : 'fa fa-times text-danger'}></i>}
                                        </td>
                                    </tr>)
                                })
                            })

                            return <>
                                {React.Children.toArray(trs)}
                            </>
                        }))}
                    </tbody>
                </table>
            </div>
        </FormCustom>
    </ReactPlaceholder>
}

ProfileForm.defaultProps = {
    data: {
        id_profile: '',
        description_profile: '',
        enabled_profile: 0,
        main_profile: ''
    },
    onSubmit: () => { },
    loadSubmit: false,
    viewSubmit: true
}

export default ProfileForm;