import React, { useState, useEffect } from 'react'
import Card from 'react-bootstrap/Card';
import Controllers from '../../Api/Controllers';
import Helpers from '../../Components/Helpers/Helpers';
import { toast } from 'react-toastify'
import { FormCustom, Select } from '../../Components/Form/Form';
import ReactPlaceholder from 'react-placeholder'
import Button from 'react-bootstrap/esm/Button';

const ReporteInventario = _ => {
    const [ready, setReady] = useState(false)
    const [loading, setLoading] = useState(false)
    const [branchOfficeSelected, setBranchOfficeSelected] = useState('')
    const [branchOffice, serBranchOffice] = useState([])
    const [reporte, setReport] = useState({})

    useEffect(() => {
        Controllers.branch_office.get_branch_office().then(res => {
            serBranchOffice(res.data)
            setReady(true)
        })
    }, [])

    const handleSubmit = _ => {
        if (!branchOfficeSelected) {
            Helpers.toast.construct({ response: 'warning', message: 'Completar campos para generar reporte', toast })
            return
        }

        setLoading(true)
        setReport({})

        Controllers.document.reporte_inventario({
            p_id_branch_office: branchOfficeSelected,
        }).then(res => {
            setReport({
                excel: res.data.excel.file,
                filename: res.data.filename,
            })
            setLoading(false)
        }).catch(req => Helpers.promise.catch({ req, toast }))
        .finally(() => setLoading(false))
    }

    console.log(reporte)

    return <>
        <ReactPlaceholder rows={10} showLoadingAnimation ready={ready}>
            <Card>
                <Card.Header className="justify-content-between">
                    <span>Reporte Inventario</span>
                </Card.Header>
                <Card.Body>
                    <FormCustom loadSubmit={loading} textButton="Generar Reporte" onSubmit={handleSubmit}>
                        <Select
                            text="Sucursal"
                            classNameParent="col-md-12 mb-2"
                            options={branchOffice.map(p => {
                                return {
                                    label: p.key_branch_office + ' - ' + p.name_branch_office,
                                    value: p.id_branch_office
                                }
                            })}
                            onChange={e => {
                                setReport({})
                                setBranchOfficeSelected(e.value)
                            }}
                        />
                    </FormCustom>
                    {!loading && reporte?.filename ? <div className='mt-4 mb-2'>
                        <a href={reporte?.excel} download={reporte?.filename}>
                            <Button size="sm" variant="success">
                                <i className="fa fa-file-excel mr-2"></i>Descargar Reporte Excel
                            </Button>
                        </a>
                    </div> : ''}
                </Card.Body>
            </Card>
        </ReactPlaceholder>
    </>
}

export default ReporteInventario