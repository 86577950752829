import React, { useState, useEffect, useMemo, useCallback } from 'react'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ReactDataTableBootstrap from '../../Components/ReactDataTableBootstrap/ReactDataTableBootstrap';
import Controllers from '../../Api/Controllers';
import DocumentForm from './DocumentForm'
import { toast } from 'react-toastify';
import Helpers from '../../Components/Helpers/Helpers';
import SweetAlert from '../../Components/SweetAlert/SweetAlert'
import { useSelector } from 'react-redux';
import { Input } from '../../Components/Form/Form'

const Document = ({ nameSingle, namePlural, separator }) => {
    const [isProcessing, setIsProccesing] = useState(true)
    const [modalShow, setModalShow] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [reload, setReload] = useState(true);
    const [rows, setRows] = useState([])
    const [loadSubmit, setLoadSubmit] = useState(false)
    const [dataUpdated, setDataUpdated] = useState({})
    const [showConfirmDelete, setShowConfirmDelete] = useState(false)
    const [loadSubmitDelete, setLoadSubmitDelete] = useState(false)
    const [dataDeleted, setDataDeleted] = useState({})

    useEffect(() => {
        if (reload) {
            setIsProccesing(true)
            Controllers.document.get_document().then(res => {
                setRows(res.data)
            }).catch(req => Helpers.promise.catch({ req, toast }))
                .finally(() => {
                    setIsProccesing(false)
                    setReload(false)
                })
        }
    }, [reload])

    const permissions = useSelector(store => store.session.permissions)
    const actionsModule = Helpers.getActions({ permissions, module: 'documentos' })

    const handleInsertUpdate = ({ data }) => {
        setLoadSubmit(true)
        Controllers.document.document_insert_update(data).then(res => {
            Helpers.toast.construct({ ...res, toast })
            if (res.response === 'success') {
                setModalShow(false)
                setDataUpdated({})
            }
        }).catch(req => Helpers.promise.catch({ req, toast }))
            .finally(() => {
                setLoadSubmit(false)
                setReload(true)
            })
    }

    const handleGet = e => {
        let $this = e.currentTarget

        if (!$this.dataset?.idDocument) {
            setModalTitle(`Registrar ${nameSingle}`)
            setDataUpdated({})
            setModalShow(true);
            return
        }

        let buttonHTML = $this.innerHTML;
        $this.innerHTML = '<i class="fa fa-circle-notch fa-spin"></i>'
        let { idDocument, view = 0, verified = 0, addDetails = 0 } = $this.dataset

        Controllers.document.get_document_id({ p_id_document: idDocument }).then(res => {
            setModalTitle(`Modificar ${nameSingle} #${idDocument}`)
            setDataUpdated({ data: res.data, viewSubmit: parseInt(view) !== 1, verified, addDetails })
            setModalShow(true);
        }).catch(req => {
            Helpers.promise.catch({ req, toast })
            setReload(true)
        })
            .finally(() => $this.innerHTML = buttonHTML)
    }

    const handleHideModalForm = () => {
        setModalShow(false)
        setLoadSubmit(false)
    }

    const handleShowModalDelete = e => {
        let { idDocument, nameDocument } = e.currentTarget.dataset
        setDataDeleted({
            p_id_document: idDocument,
            p_name_document: nameDocument
        })
        setShowConfirmDelete(true)
        setDataAlert({
            icon: 'delete',
            title: 'anular el documento',
            confirmText: '¡Sí, anular!',
            cancelText: '¡No, cancelar!',
            onCancel: handleHideModalDelete,
            onConfirm: _ => {
                Controllers.document.document_delete({
                    p_id_document: idDocument,
                    p_name_document: nameDocument
                }).then(res => {
                    Helpers.toast.construct({ ...res, toast })
                    if (res.response === 'success') {
                        setShowConfirmDelete(false)
                        setDataDeleted({})
                    }
                }).catch(req => Helpers.promise.catch({ req, toast }))
                    .finally(() => {
                        setReload(true)
                        setLoadSubmitDelete(false)
                    })
            }
        })
    }

    const handleShowModalApprove = e => {
        let { idDocument, nameDocument } = e.currentTarget.dataset
        setDataDeleted({
            p_id_document: idDocument,
            p_name_document: nameDocument
        })
        setShowConfirmDelete(true)
        setDataAlert({
            icon: 'approve',
            title: 'aprobar la solicitud de producción',
            confirmText: '¡Sí, aprobar!',
            cancelText: '¡No, cancelar!',
            onCancel: handleHideModalDelete,
            onConfirm: _ => {
                if (document.querySelector('#txt_date_production').value === '') {
                    Helpers.toast.construct({ response: 'warning', message: 'Ingresar fecha estimada de producción', toast })
                    return false
                }

                 setLoadSubmitDelete(true)
                 Controllers.document.document_anulado_change({
                     p_id_document: idDocument,
                     p_anulado: 6,
                     p_date_production: document.querySelector('#txt_date_production').value
                 }).then(_ => {
                     Helpers.toast.construct({ response: 'success', message: 'Solicitud aprobada', toast })
                     setShowConfirmDelete(false)
                     setDataDeleted({})
                 }).catch(req => Helpers.promise.catch({ req, toast }))
                     .finally(() => {
                         setReload(true)
                         setLoadSubmitDelete(false)
                     })
            },
            content: _ => <>
                <Input
                    text="Fecha estimada de producción"
                    type="date"
                    classNameParent="w-100"
                    id="txt_date_production"
                />
            </>
        })
    }

    const handleShowModalSendBranchOffice = e => {
        let { idDocument, nameDocument } = e.currentTarget.dataset
        setDataDeleted({
            p_id_document: idDocument,
            p_name_document: nameDocument
        })
        setShowConfirmDelete(true)
        setDataAlert({
            icon: 'approve',
            title: 'enviar la producción a la sucursal',
            confirmText: '¡Sí, enviar!',
            cancelText: '¡No, cancelar!',
            onCancel: handleHideModalDelete,
            onConfirm: _ => {
                setLoadSubmitDelete(true)
                Controllers.document.document_anulado_change({
                    p_id_document: idDocument,
                    p_anulado: 7
                }).then(_ => {
                    Helpers.toast.construct({ response: 'success', message: 'Producción enviada a sucursal', toast })
                    setShowConfirmDelete(false)
                    setDataDeleted({})
                }).catch(req => Helpers.promise.catch({ req, toast }))
                    .finally(() => {
                        setReload(true)
                        setLoadSubmitDelete(false)
                    })
            }
        })
    }

    const handleHideModalDelete = () => setShowConfirmDelete(false)

    const dtRows = useMemo(() => <ReactDataTableBootstrap
        head={[
            [
                { name: 'id_document', text: 'ID', align: 'center' },
                { name: 'key_branch_office', text: 'Sucursal', align: 'center' },
                { name: 'name_branch_office', text: 'Nombre', align: 'center' },
                /* { name: 'number_invoice_doc', text: 'Folio', align: 'center' }, */
                { name: 'name_document', text: 'Tipo', align: 'center' },
                { name: 'observation_doc', text: 'Comentarios' },
                { name: 'date_doc', text: 'Fecha', align: 'center' },
                { name: 'code_period', text: 'Periodo', align: 'center' },
                {
                    name: 'anulado', text: 'Estado', align: 'center', render: r => {
                        return <div className={'text-center font-weight-bold text-' + (r.anulado_color)}>
                            {r.anulado_text}
                        </div>
                    }
                },
                {
                    name: 'actions', text: <i className="fa fa-cog"></i>, align: 'center', render: u => {
                        return <div>
                            {actionsModule?.view ? <Button variant={actionsModule?.view.color_action} className="mr-1" size="xs" data-view={1} data-id-document={u.id_document} data-name-document={u.name_document} onClick={handleGet}>
                                <i className={actionsModule?.view.icon}></i>
                            </Button> : ''}
                            {actionsModule?.approve_production && ((parseInt(u.anulado) === 2 && parseInt(u.id_type_document) === 3) || (parseInt(u.anulado) === 7 && parseInt(u.id_type_document) === 4)) ? <Button variant={actionsModule?.confirm.color_action} className="mr-1" size="xs" data-verified={1} data-view={1} data-id-document={u.id_document} data-name-document={u.name_document} onClick={handleGet}>
                                <i className={actionsModule?.confirm.icon}></i>
                            </Button> : ''}
                            {actionsModule?.approve_production && parseInt(u.id_type_document) === 4 && parseInt(u.anulado) === 2 ? <Button variant={actionsModule?.approve_production.color_action} className="mr-1" size="xs" data-id-document={u.id_document} data-name-document={u.name_document} onClick={handleShowModalApprove}>
                                <i className={actionsModule?.approve_production.icon}></i>
                            </Button> : ''}
                            {parseInt(u.anulado) === 2 && actionsModule?.update ? <Button variant={actionsModule?.update.color_action} className="mr-1" size="xs" data-id-document={u.id_document} data-name-document={u.name_document} onClick={handleGet}>
                                <i className={actionsModule?.update.icon}></i>
                            </Button> : ''}
                            {parseInt(u.anulado) !== 1 && parseInt(u.anulado) !== 5 && actionsModule?.ban ? <Button variant={actionsModule?.ban.color_action} className="mr-1" size="xs" data-id-document={u.id_document} data-name-document={u.name_document} onClick={handleShowModalDelete}>
                                <i className={actionsModule?.ban.icon}></i>
                            </Button> : ''}
                            {parseInt(u.anulado) === 6 && actionsModule?.send_branch_office ? <Button variant={actionsModule?.send_branch_office.color_action} size="xs" data-id-document={u.id_document} data-name-document={u.name_document} onClick={handleGet} data-view={1} data-add-details={1}>
                                <i className={actionsModule?.send_branch_office.icon}></i>
                            </Button> : ''}
                        </div>
                    }
                },
            ],
        ]}
        rows={rows}
        isProcessing={isProcessing}
        pageLength={25}
    />, [rows, isProcessing])

    const [dataAlert, setDataAlert] = useState({
        icon: '',
        title: '',
        confirmText: '',
        cancelText: '',
        onCancel: _ => { },
        onConfirm: _ => { }
    })

    return <>
        <Card>
            <Card.Header className="justify-content-between">
                <span>Listado De {namePlural}</span>
                {actionsModule?.new ? <Button variant={actionsModule?.new.color_action} size="sm" onClick={handleGet}>
                    <i className="fa fa-plus mr-1"></i> {actionsModule?.new.name_action} {nameSingle}
                </Button> : ''}
            </Card.Header>
            <Card.Body>
                {dtRows}
            </Card.Body>
        </Card>
        <Modal show={modalShow} onHide={handleHideModalForm} backdrop="static" size="xl">
            <Modal.Header closeButton>
                <Modal.Title as="div">{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <DocumentForm submitStatus={_ => {
                    setModalShow(false)
                    setDataUpdated({})
                    setReload(true)
                }} onSubmit={handleInsertUpdate} loadSubmit={loadSubmit} {...dataUpdated} />
            </Modal.Body>
        </Modal>
        <SweetAlert
            icon={dataAlert.icon}
            show={showConfirmDelete}
            showCancelButton
            title={<>¿Desea {dataAlert.title}?</>}
            subtitle={<>#{dataDeleted.p_id_document} - {dataDeleted.p_name_document}</>}
            confirmButtonText={dataAlert.confirmText}
            cancelButtonText={dataAlert.cancelText}
            loadSubmit={loadSubmitDelete}
            onCancel={dataAlert.onCancel}
            onConfirm={dataAlert.onConfirm}
            content={dataAlert.content}
        />
    </>
}

export default Document