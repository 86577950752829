import React, { useState, useMemo, useEffect, useCallback } from 'react'
import Button from 'react-bootstrap/esm/Button'
import ReactPlaceholder from 'react-placeholder/lib'
import Controllers from '../../Api/Controllers'
import { Input, FormCustom, TextArea, Select, CheckBox } from '../../Components/Form/Form'
import Helpers from '../../Components/Helpers/Helpers'
import ProductList from '../Product/ProductList'
import { toast } from 'react-toastify';

const DocumentForm = ({ data, onSubmit, disablebSubmit, loadSubmit, viewSubmit, verified, submitStatus, addDetails }) => {
    const [row, setRow] = useState({
        p_id_document: data.id_document,
        p_number_invoice_doc: data.number_invoice_doc,
        p_number_lot_doc: data.number_lot_doc,
        p_id_period: data.id_period,
        p_code_period: data.code_period,
        p_id_type_document: data.id_type_document,
        p_observation_doc: data.observation_doc,
        p_id_branch_office_origin: data.id_branch_office_origin,
        p_id_branch_office_destination: data.id_branch_office_destination,
        p_date_doc: data.date_doc,
        p_details: data.details,
    })

    const [ready, setReady] = useState(false)
    const [rowsTypeDocument, setRowsTypeDocument] = useState([])
    const [rowsBranchOffice, setRowsBranchOffice] = useState([])
    const [rowsLaboratory, setRowsLaboratory] = useState([])
    const [modalProducts, setModalProducts] = useState(false)

    const [loadStatus, setLoadStatus] = useState(false)
    const [enabledChecks, setEnabledChecks] = useState(true)
    const [status, setStatus] = useState({
        p_anulado: '',
        p_observation_rejected: ''
    })

    const handleShowModalProducts = () => {
        if (row.p_id_branch_office_origin && !isNaN(parseInt(row.p_id_type_document))) {
            setModalProducts(true)
        } else {
            Helpers.toast.construct({ response: 'warning', message: 'Por favor, seleccionar Sucursal y Tipo de Documento', toast })
        }
    }
    const handleHideModalProducts = () => setModalProducts(false)

    const [isProduction, setIsProduction] = useState(isNaN(row.p_id_document) ? false : (parseInt(row.p_id_type_document) === 4))

    const [isMass, setIsMass] = useState(false)
    const [validateProducts, setValidateProducts] = useState(false)
    const [listProducts, setListProducts] = useState('')

    useEffect(_ => {
        setIsProduction(parseInt(row.p_id_type_document) === 4)
    }, [row.p_id_type_document])

    useEffect(() => {
        let promises = [
            Controllers.type_document.get_type_document(),
            Controllers.branch_office.get_branch_office(),
            Controllers.period.get_period_current(),
            Controllers.branch_office.get_branch_office(),
        ]

        Promise.all(promises).then(res => {
            setRowsTypeDocument([
                ...res[0].data,
            ])
            setRowsBranchOffice(res[1].data)
            setRowsLaboratory(res[3].data)
            if (res[2].data?.id_period && data.id_document === '') {
                setRow({ ...row, p_id_period: res[2].data?.id_period, p_code_period: res[2].data?.code_period })
            }
            setReady(true)
        })
    }, [data.id_document])

    useEffect(_ => {
        let existsError = row.p_details.some(p => parseInt(p.enabled) === 0)
        if (existsError) {
            setStatus({
                ...status,
                p_anulado: 4
            })
            setEnabledChecks(false)
        } else {
            setEnabledChecks(true)
            setStatus({
                ...status,
                p_anulado: ''
            })
        }
    }, [row.p_details])

    const idTypeDocument = useMemo(() => {
        if (!row.p_id_type_document) {
            return
        }

        let finded = rowsTypeDocument.find(t => parseInt(row.p_id_type_document) === parseInt(t.id_type_document))

        if (!finded) {
            return
        }
        return {
            label: finded.name_document,
            value: finded.id_type_document
        }
    }, [row.p_id_type_document, rowsTypeDocument])

    const idBranchOfficeOrigin = useMemo(() => {
        if (!row.p_id_branch_office_origin) {
            return ''
        }

        let finded = rowsBranchOffice.find(t => parseInt(row.p_id_branch_office_origin) === parseInt(t.id_branch_office))

        if (!finded) {
            return ''
        }
        return {
            label: finded.name_branch_office,
            value: finded.id_branch_office
        }
    }, [row.p_id_branch_office_origin, rowsBranchOffice])

    const idBranchOfficeDestination = useMemo(() => {
        if (!row.p_id_branch_office_destination) {
            return ''
        }

        let rowsBo = isProduction ? rowsLaboratory : rowsBranchOffice
        let finded = rowsBo.find(t => parseInt(row.p_id_branch_office_destination) === parseInt(t.id_branch_office))

        if (!finded) {
            return ''
        }
        return {
            label: finded.name_branch_office,
            value: finded.id_branch_office
        }
    }, [row.p_id_branch_office_destination, rowsBranchOffice, rowsLaboratory, isProduction])

    const getSubtotal = useCallback(() => {
        let total = 0
        row.p_details.forEach(r => {
            let subt = parseFloat(r.qty) * parseFloat(r.price)
            if (!isNaN(parseFloat(r.qty) * parseFloat(r.price))) {
                total += subt
            }
        })

        let iva = total * Helpers.config.percentageIva

        return {
            total: total.toFixed(2),
            subtotal: (total - iva).toFixed(2),
            iva: iva.toFixed(2)
        }
    }, [row.p_details])

    const dataSubmit = useCallback(() => {
        return {
            ...row,
            p_details: row.p_details.map(r => {
                return {
                    p_id_product: r.id_product,
                    p_key_product: r.key_product,
                    p_name_product: r.name_product,
                    p_lot: r.lot,
                    p_price: r.price,
                    p_qty: r.qty,
                    p_date_expiration: r.date_expiration
                }
            }),
            p_total_doc: getSubtotal().total,
            p_subtotal_doc: getSubtotal().subtotal,
            p_iva_doc: getSubtotal().iva
        }
    }, [row, getSubtotal])

    return <ReactPlaceholder rows={7} ready={ready}>
        <FormCustom dataSubmit={dataSubmit()} onSubmit={({ data }) => {
            if (!row.p_id_period) {
                Helpers.toast.construct({ response: 'warning', message: 'No hay periodo activo. Comunicarse con el Área responsable.', toast })
                return
            }
            if (!row.p_id_type_document) {
                Helpers.toast.construct({ response: 'warning', message: 'Por favor, seleccione el Tipo de Documento.', toast })
                return
            }

            if (!row.p_id_branch_office_origin) {
                Helpers.toast.construct({ response: 'warning', message: 'Por favor, seleccione Sucursal Origen.', toast })
                return
            }

            if (row.p_details.length === 0) {
                Helpers.toast.construct({ response: 'warning', message: 'Por favor, agregar productos.', toast })
                return
            }

            if ((parseInt(row.p_id_type_document) === 3 || parseInt(row.p_id_type_document) === 5) && parseInt(row.p_id_branch_office_origin) === parseInt(row.p_id_branch_office_destination)) {
                Helpers.toast.construct({ response: 'warning', message: 'No puede realizar un traspaso de la misma sucursal', toast })
                return
            }

            if (addDetails) {
                data.p_add_details = 1
            }

            return onSubmit({ data })
        }} disablebSubmit={disablebSubmit} viewSubmit={viewSubmit || addDetails} loadSubmit={loadSubmit}>
            {/* <Input
                text="Folio"
                placeholder="Folio"
                classNameParent="col-6 col-md-4 mb-2"
                icon="far fa-comment-alt"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.number_invoice_doc}
                onChange={e => setRow({ ...row, p_number_invoice_doc: e.currentTarget.value })}
                maxLength={100}
            /> */}
            {/* <Input
                text="Nro Lote"
                placeholder="Nro Lote"
                classNameParent="col-6 col-md-4 mb-2"
                icon="far fa-comment-alt"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.number_lot_doc}
                onChange={e => setRow({ ...row, p_number_lot_doc: e.currentTarget.value })}
                maxLength={100}
            /> */}
            <Input
                text="Periodo"
                placeholder="Periodo"
                classNameParent="col-6 col-md-4 mb-2"
                icon="far fa-comment-alt"
                required
                invalid="El campo es obligatorio."
                defaultValue={row.p_code_period}
                disabled
                className="text-center"
            />
            <Input
                text="Fecha"
                placeholder="Fecha"
                type="date"
                classNameParent="col-6 col-md-4 mb-2"
                icon="far fa-comment-alt"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.date_doc}
                onChange={e => setRow({ ...row, p_date_doc: e.currentTarget.value })}
                disabled={!viewSubmit}
            />
            <Select
                classNameParent="col-6 col-md-4 mb-2"
                icon="fas fa-percentage"
                text="Tipo Documento"
                options={rowsTypeDocument.map(t => {
                    return {
                        label: t.name_document,
                        value: t.id_type_document
                    }
                })}
                disabled={data.id_document !== ''}
                value={idTypeDocument}
                onChange={e => {
                    setRow({ ...row, p_id_type_document: e.value, p_details: [], p_id_branch_office_destination: parseInt(e.value) === 5 ? 12 : '', p_id_branch_office_origin: '' })
                }}
            />
            <Select
                classNameParent="col-6 col-md-4 mb-2"
                icon="fas fa-percentage"
                text={isProduction ? 'Sucursal Solicitante' : 'Sucursal Origen'}
                disabled={data.id_document !== ''}
                options={rowsBranchOffice.map(t => {
                    return {
                        label: t.name_branch_office,
                        value: t.id_branch_office
                    }
                })}
                value={idBranchOfficeOrigin}
                onChange={e => {
                    if (data.id_document === '') {
                        if (parseInt(e.value) !== parseInt(row.p_id_branch_office_origin)) {
                            setRow({ ...row, p_id_branch_office_origin: e.value, p_details: [] })
                        }
                    }
                }}
            />
            {(parseInt(row.p_id_type_document) === 3 || parseInt(row.p_id_type_document) === 5 || parseInt(row.p_id_type_document) === 4) ? <Select
                classNameParent="col-6 col-md-4 mb-2"
                icon="fas fa-percentage"
                text={isProduction ? 'Laboratorio a Producir' : 'Sucursal Destino'}
                options={(isProduction ? rowsLaboratory : rowsBranchOffice).map(t => {
                    return {
                        label: t.name_branch_office,
                        value: t.id_branch_office
                    }
                })}
                value={idBranchOfficeDestination}
                onChange={e => setRow({ ...row, p_id_branch_office_destination: e.value, p_details: [] })}
                disabled={data.id_document !== '' || parseInt(row.p_id_type_document) === 5}
            /> : ''}
            {(parseInt(row.p_id_type_document) === 3 || parseInt(row.p_id_type_document) === 5) && parseInt(row.p_id_branch_office_origin) === parseInt(row.p_id_branch_office_destination) && <div className="col-12">
                <label className='text-danger'>No puede realizar un traspaso de la misma sucursal</label>
            </div>}
            <TextArea
                text="Comentarios"
                placeholder="Comentarios"
                classNameParent="col-12 mb-2"
                icon="far fa-comment-alt"
                required={parseInt(row.p_id_type_document) !== 3}
                invalid="El campo es obligatorio."
                defaultValue={data.observation_doc}
                onChange={e => setRow({ ...row, p_observation_doc: e.currentTarget.value })}
                rows="4"
                disabled={!viewSubmit}
            />
            <div className="mb-2 col-12">
                {isNaN(parseInt(row.p_id_document)) ? <Button size="sm" onClick={handleShowModalProducts}>
                    <i className="fa fa-plus mr-1"></i>Agregar Producto
                </Button> : ''}
                {isNaN(parseInt(row.p_id_document)) ? <Button className="ml-3" size="sm" variant={isMass ? 'dark' : 'outline-dark'} onClick={_ => setIsMass(!isMass)}>Carga Masiva</Button> : ''}
            </div>

            {isMass && isNaN(parseInt(row.p_id_document)) ? <div className="col-12 mb-2">
                <TextArea
                    text="Listado de productos"
                    value={listProducts}
                    onChange={e => setListProducts(e.currentTarget.value)}
                    classNameParent="w-100 mb-2"
                    rows={5}
                    placeholder="Producto/cantidad separado por coma. Producto por línea:
                        ClaveProducto1, 5
                        ClaveProducto2, 10
                        "
                />
                <Button disabled={validateProducts} variant="info" size="sm" onClick={_ => {
                    if (!row.p_id_branch_office_origin) {
                        Helpers.toast.construct({ response: 'warning', message: 'Por favor, seleccionar Sucursal', toast })
                        return
                    }
                    if (!listProducts) {
                        Helpers.toast.construct({ response: 'warning', message: 'Por favor, agregar productos al listado', toast })
                        return
                    }
                    let existsError = false
                    let listProductsFormated = []
                    listProducts.split('\n').forEach(s => {
                        if (!existsError) {
                            let splitLine = s.split(',')
                            if (splitLine.length !== 2) {
                                existsError = true
                            } else {
                                if (isNaN(parseInt(splitLine[1]))) {
                                    existsError = true
                                } else {
                                    if (!listProductsFormated.find(pf => pf.key_product === splitLine[0])) {
                                        listProductsFormated.push({
                                            key_product: splitLine[0],
                                            qty: parseInt(splitLine[1])
                                        })
                                    }
                                }
                            }
                        }
                    })

                    if (existsError) {
                        Helpers.toast.construct({ response: 'warning', message: 'Por favor, revisar el formato del listado', toast })
                        return
                    }

                    setValidateProducts(true)
                    Controllers.product.get_product({
                        p_id_branch_office: row.p_id_branch_office_origin,
                    }).then(res => {
                        let newDetails = []

                        listProductsFormated.forEach(p => {
                            let existsProductKey = res.data.find(r => r.key_product === p.key_product)
                            if (existsProductKey && !row.p_details.some(r => r.key_product === p.key_product)) {
                                newDetails.push({
                                    id_product: existsProductKey.id_product,
                                    key_product: existsProductKey.key_product,
                                    name_product: existsProductKey.name_product,
                                    lot: '',
                                    price: '',
                                    qty: p.qty,
                                    date_expiration: ''
                                })
                            }
                        })

                        if (parseInt(row.p_id_type_document) === 2 || parseInt(row.p_id_type_document) === 3 || parseInt(row.p_id_type_document) === 5) {

                            let newDetailsLot = []
                            Promise.all(newDetails.map(n => {
                                return Controllers.product.get_product_lot({
                                    p_id_product: n.id_product
                                })
                            })).then(res => {

                                res.forEach(r => {
                                    if (r.data.length > 0) {
                                        let findDet = newDetails.find(nd => parseInt(nd.id_product) === parseInt(r.data[0].id_product))

                                        console.log(findDet)
                                        if (findDet) {
                                            findDet.lots = r.data
                                            newDetailsLot.push(findDet)
                                        }
                                    }
                                })

                                setListProducts('')
                                setIsMass(false)
                                setRow({ ...row, p_details: [...row.p_details, ...newDetailsLot] })
                            })
                        } else {
                            setListProducts('')
                            setIsMass(false)
                            setRow({ ...row, p_details: [...row.p_details, ...newDetails] })
                        }
                    }).catch(req => Helpers.promise.catch({ req, toast }))
                        .finally(_ => {
                            setValidateProducts(false)
                        })
                }}>
                    {validateProducts ? <i className="fa fa-circle-notch fa-spin"></i> : <><i className="fa fa-check"></i> Agregar Productos</>}
                </Button>
            </div> : ''}

            <div className="table-responsive col-12">
                <table className="table table-sm table-hover table-striped table-bordered">
                    <thead>
                        <tr>
                            {verified || ((parseInt(row.p_id_type_document) === 3 || parseInt(row.p_id_type_document) === 5 || parseInt(row.p_id_type_document) === 4) && !isNaN(parseInt(row.p_id_document))) ? <th>¿OK?</th> : ''}
                            <th>Clave</th>
                            <th>Descripción</th>
                            <th>Cantidad</th>
                            <th>Precio</th>
                            {(parseInt(row.p_id_type_document) === 4 && (addDetails || parseInt(data.anulado) === 7 || parseInt(data.anulado) === 4 || parseInt(data.anulado) === 5 || parseInt(data.anulado) === 3 || parseInt(data.anulado) === 1)) || parseInt(row.p_id_type_document) <= 3 ? <>

                                <th style={{
                                    minWidth: 200
                                }}>Lote</th>
                                <th>Fecha Caducidad</th>
                            </> : <>
                                <th></th>
                                <th></th>
                            </>}
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {React.Children.toArray(row.p_details.map(r => {
                            let findLot = r?.lots?.find(l => l.lot === r.lot && l.date_expiration === r.date_expiration)

                            return <tr>
                                {verified || ((parseInt(row.p_id_type_document) === 3 || parseInt(row.p_id_type_document) === 5 || parseInt(row.p_id_type_document) === 4) && !isNaN(parseInt(row.p_id_document))) ? <td className="text-center">
                                    <CheckBox
                                        id={'verified-' + r.id_product}
                                        classNameParent=""
                                        onChange={e => {
                                            setRow({
                                                ...row,
                                                p_details: row.p_details.map(rr => {
                                                    if (parseInt(r.id_product) === parseInt(rr.id_product)) {
                                                        rr.enabled = e.currentTarget.checked ? 1 : 0
                                                    }
                                                    return rr
                                                })
                                            })
                                        }}
                                        checked={parseInt(r.enabled) === 1}
                                        disabled={!verified}
                                    />
                                </td> : ''}
                                <td>{r.key_product}</td>
                                <td>{r.name_product}</td>
                                <td>
                                    <Input
                                        className="text-center"
                                        required
                                        type="number"
                                        min="1"
                                        step="1"
                                        {...(parseInt(row.p_id_type_document) === 1 ? {} : {
                                            max: findLot?.exists_current_qty_product
                                        })}
                                        value={!isNaN(parseInt(r.qty)) ? r.qty : ''}
                                        onChange={e => {
                                            setRow({
                                                ...row,
                                                p_details: row.p_details.map(rr => {
                                                    if (parseInt(r.id_product) === parseInt(rr.id_product)) {
                                                        rr.qty = e.currentTarget.value
                                                    }
                                                    return rr
                                                })
                                            })
                                        }}
                                        style={{
                                            minWidth: '100px'
                                        }}
                                        disabled={!viewSubmit}
                                    />
                                </td>
                                <td>
                                    <Input
                                        className="text-center"
                                        required
                                        type="number"
                                        min="0.10"
                                        step="0.10"
                                        value={!isNaN(parseInt(r.price)) ? r.price : ''}
                                        onChange={e => {
                                            setRow({
                                                ...row,
                                                p_details: row.p_details.map(rr => {
                                                    if (parseInt(r.id_product) === parseInt(rr.id_product)) {
                                                        rr.price = e.currentTarget.value
                                                    }
                                                    return rr
                                                })
                                            })
                                        }}
                                        style={{
                                            minWidth: '100px'
                                        }}
                                        disabled={!viewSubmit}
                                    />
                                </td>
                                {(parseInt(row.p_id_type_document) === 4 && (addDetails || parseInt(data.anulado) === 7 || parseInt(data.anulado) === 4 || parseInt(data.anulado) === 5 || parseInt(data.anulado) === 3 || parseInt(data.anulado) === 1)) || parseInt(row.p_id_type_document) <= 3 ? <>
                                    <td>
                                        {parseInt(row.p_id_type_document) === 1 || parseInt(row.p_id_type_document) === 4 ? <Input
                                            className="text-center"
                                            required
                                            value={r.lot}
                                            onChange={e => {
                                                setRow({
                                                    ...row,
                                                    p_details: row.p_details.map(rr => {
                                                        if (parseInt(r.id_product) === parseInt(rr.id_product)) {
                                                            rr.lot = e.currentTarget.value
                                                        }
                                                        return rr
                                                    })
                                                })
                                            }}
                                            style={{
                                                minWidth: '100px'
                                            }}
                                            disabled={!viewSubmit && !addDetails}
                                        /> : <>
                                            <Select
                                                options={r?.lots?.map(l => {
                                                    return {
                                                        label: l.lot + ` (${l.date_expiration})`,
                                                        value: l.lot,
                                                        date_expiration: l.date_expiration
                                                    }
                                                })}
                                                value={findLot ? {
                                                    label: findLot.lot + ` (${findLot.date_expiration})`,
                                                    value: findLot.lot,
                                                    date_expiration: findLot.date_expiration
                                                } : ''}
                                                onChange={e => {
                                                    setRow({
                                                        ...row,
                                                        p_details: row.p_details.map(rr => {
                                                            if (parseInt(r.id_product) === parseInt(rr.id_product)) {
                                                                rr.lot = e.value
                                                                rr.date_expiration = e.date_expiration
                                                            }
                                                            return rr
                                                        })
                                                    })
                                                }}
                                                disabled={!viewSubmit}
                                            />
                                        </>}
                                    </td>
                                    <td>
                                        <Input
                                            type="date"
                                            className="text-center"
                                            required
                                            value={parseInt(row.p_id_type_document) === 1 || parseInt(row.p_id_type_document) === 4 ? r.date_expiration :
                                                (findLot ? findLot.date_expiration : '')}
                                            onChange={e => {
                                                setRow({
                                                    ...row,
                                                    p_details: row.p_details.map(rr => {
                                                        if (parseInt(r.id_product) === parseInt(rr.id_product)) {
                                                            rr.date_expiration = e.currentTarget.value
                                                        }
                                                        return rr
                                                    })
                                                })
                                            }}
                                            style={{
                                                minWidth: '100px'
                                            }}
                                            disabled={(parseInt(row.p_id_type_document) !== 1 && parseInt(row.p_id_type_document) !== 4) || !viewSubmit && !addDetails}
                                        />
                                    </td>
                                </> : <>
                                    <td></td>
                                    <td></td>
                                </>}
                                <td>
                                    {isNaN(parseInt(row.p_id_document)) ? <Button size="xs" variant="danger" onClick={() => {
                                        setRow({ ...row, p_details: row.p_details.filter(rr => parseInt(rr.id_product) !== parseInt(r.id_product)) })
                                    }}>
                                        <i className="fa fa-trash"></i>
                                    </Button> : ''}
                                </td>
                            </tr>
                        }))}
                        <tr>
                            {verified || ((parseInt(row.p_id_type_document) === 3 || parseInt(row.p_id_type_document) === 5 || parseInt(row.p_id_type_document) === 4) && !isNaN(parseInt(row.p_id_document))) ? <td></td> : ''}
                            <td></td>
                            <td></td>
                            <td align="center">Subtotal</td>
                            <td align="center">{getSubtotal().subtotal}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            {verified || ((parseInt(row.p_id_type_document) === 3 || parseInt(row.p_id_type_document) === 5 || parseInt(row.p_id_type_document) === 4) && !isNaN(parseInt(row.p_id_document))) ? <td></td> : ''}
                            <td></td>
                            <td></td>
                            <td align="center">IVA</td>
                            <td align="center">{getSubtotal().iva}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            {verified || ((parseInt(row.p_id_type_document) === 3 || parseInt(row.p_id_type_document) === 5 || parseInt(row.p_id_type_document) === 4) && !isNaN(parseInt(row.p_id_document))) ? <td></td> : ''}
                            <td></td>
                            <td></td>
                            <td align="center">Total</td>
                            <td align="center">{getSubtotal().total}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </FormCustom>

        {!isNaN(parseInt(row.p_id_document)) && data.observation_confirm ? <>
            <div>
                <strong>Observación</strong>
                <p className="">{data.observation_confirm}</p>
            </div>
        </> : ''}

        {!isNaN(parseInt(row.p_id_document)) && data.date_production ? <>
            <div className="mt-4 mb-4">
                <span>Fecha estimada de producción: </span>
                <strong className="">{data.date_production}</strong>
            </div>
        </> : ''}

        {verified ? <>
            <div className="d-flex flex-column flex-lg-row">
                <CheckBox
                    id="approve"
                    text="Aprobar"
                    classNameParent="mr-4"
                    onChange={_ => setStatus({ ...status, p_anulado: 3 })}
                    checked={parseInt(status.p_anulado) === 3}
                    disabled={!enabledChecks}
                />
                <CheckBox
                    id="approve-observation"
                    text="Aprobar con Observación"
                    classNameParent="mr-4"
                    onChange={_ => setStatus({ ...status, p_anulado: 4 })}
                    checked={parseInt(status.p_anulado) === 4}
                    disabled={!enabledChecks}
                />
                <CheckBox
                    id="rejected"
                    text="Rechazar"
                    classNameParent="mr-4"
                    onChange={_ => setStatus({ ...status, p_anulado: 5 })}
                    checked={parseInt(status.p_anulado) === 5}
                    disabled={!enabledChecks}
                />
            </div>
            <TextArea
                placeholder="Ingresar observación"
                text="Observación"
                value={status.p_observation_confirm}
                classNameParent="w-100 mt-2 mb-2"
                rows={5}
                onChange={e => setStatus({ ...status, p_observation_confirm: e.currentTarget.value })}
            />
            <Button disabled={loadStatus} className="btn btn-success" size="sm" onClick={_ => {
                if (!status.p_anulado) {
                    Helpers.toast.construct({ response: 'warning', message: 'Seleccionar aprobación/rechazo', toast })
                    return
                }

                setLoadStatus(true)
                Controllers.document.document_approve_rejected({
                    p_id_document: row.p_id_document,
                    p_anulado: status.p_anulado,
                    p_observation_confirm: status.p_observation_confirm,
                    p_details: row.p_details.filter(p => parseInt(p.enabled) === 0).map(p => {
                        return {
                            id_product: p.id_product,
                            enabled: p.enabled,
                            lot: p.lot,
                        }
                    })
                }).then(res => {
                    Helpers.toast.construct({ ...res, toast })
                    setLoadStatus(false)
                    submitStatus();
                })
            }}>
                {!loadStatus ? <><i className="fa fa-check"></i> Actualizar Documento</> : <i className="fa fa-circle-notch fa-spin"></i>}
            </Button>
        </> : ''}

        <ProductList showModal={modalProducts} viewPack={false} hideModal={handleHideModalProducts} nameSingle="Producto" namePlural="Productos" separator="el" added={row.p_details} idBranchOffice={row.p_id_branch_office_origin} addItem={({ idProduct, keyProduct, nameProduct, priceProduct, priceProductType2 }) => {
            let newDet = {
                id_product: idProduct,
                key_product: keyProduct,
                name_product: nameProduct,
                lot: '',
                price: parseInt(row.p_id_type_document) === 2 || parseInt(row.p_id_type_document) === 3 || parseInt(row.p_id_type_document) === 5 ? priceProductType2 : '',
                qty: '',
                date_expiration: ''
            }

            if (parseInt(row.p_id_type_document) === 2 || parseInt(row.p_id_type_document) === 3 || parseInt(row.p_id_type_document) === 5) {
                Controllers.product.get_product_lot({
                    p_id_product: idProduct
                }).then(res => {
                    if (res.data.length === 0) {
                        Helpers.toast.construct({ response: 'warning', message: 'No hay lote/stock disponible.', toast })
                        return
                    }

                    console.log(res.data)
                    res.data.forEach(lt => {
                        if (newDet.date_expiration === '') {
                            newDet.lot = lt.lot
                            newDet.date_expiration = lt.date_expiration
                        } else if (lt.date_expiration < newDet.date_expiration) {
                            newDet.lot = lt.lot
                            newDet.date_expiration = lt.date_expiration
                        }
                    })

                    console.log(newDet)
                    newDet = {
                        ...newDet,
                        lots: res.data
                    }
                    setRow({ ...row, p_details: [...row.p_details, newDet] })
                })
            } else {
                setRow({ ...row, p_details: [...row.p_details, newDet] })
            }
        }} />
    </ReactPlaceholder>
}

DocumentForm.defaultProps = {
    data: {
        id_document: '',
        number_invoice_doc: '',
        number_lot_doc: '',
        code_period: '',
        id_period: '',
        id_type_document: '',
        observation_doc: '',
        id_branch_office_origin: '',
        id_branch_office_destination: '',
        date_doc: Helpers.date.get({ 'format': '%Y-%m-%d' }),
        details: []
    },
    onSubmit: () => { },
    loadSubmit: false,
    viewSubmit: true,
    verified: false,
    submitStatus: _ => { },
    addDetails: false,
}

export default DocumentForm;