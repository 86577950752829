import React, { useState, useEffect, useMemo, useCallback } from 'react'
import Card from 'react-bootstrap/Card';
import Controllers from '../../Api/Controllers';
import Helpers from '../../Components/Helpers/Helpers';
import { toast } from 'react-toastify'
import { FormCustom, Select, Input } from '../../Components/Form/Form';
import ReactPlaceholder from 'react-placeholder'
import Button from 'react-bootstrap/esm/Button';

const Kardex = _ => {
    const [ready, setReady] = useState(false)
    const [loading, setLoading] = useState(false)
    const [branchOfficeSelected, setBranchOfficeSelected] = useState('')
    const [branchOffice, serBranchOffice] = useState([])
    const [products, setProducts] = useState([])
    const [productSelected, setProductSelected] = useState('')
    const [reporte, setReport] = useState({})
    const [data, setData] = useState({
        p_date_start: '',
        p_date_end: ''
    })

    useEffect(() => {
        Controllers.branch_office.get_branch_office().then(res => {
            serBranchOffice(res.data)
            setReady(true)
        })
    }, [])

    const handleSubmit = _ => {
        if (!branchOfficeSelected || !productSelected) {
            Helpers.toast.construct({ response: 'warning', message: 'Completar campos para generar reporte', toast })
            return
        }

        setLoading(true)
        setReport({})

        Controllers.document.kardex_branch_office_product({
            p_id_branch_office: branchOfficeSelected,
            p_id_product: productSelected,
            p_date_start: data.p_date_start,
            p_date_end: data.p_date_end
        }).then(res => {
            setReport({
                excel: res.data.excel.file,
                filename: res.data.filename,
            })
            setLoading(false)
        }).catch(req => Helpers.promise.catch({ req, toast }))
        .finally(() => setLoading(false))
    }

    console.log(reporte)

    return <>
        <ReactPlaceholder rows={10} showLoadingAnimation ready={ready}>
            <Card>
                <Card.Header className="justify-content-between">
                    <span>Kardex</span>
                </Card.Header>
                <Card.Body>
                    <FormCustom loadSubmit={loading} textButton="Generar Reporte" onSubmit={handleSubmit}>
                        <Input
                            classNameParent="col-6 mb-4"
                            type="date"
                            icon="far fa-calendar-alt"
                            placeholder="Fecha Inicio"
                            text="Fecha Inicio"
                            required
                            value={data.p_date_start}
                            onChange={e => {
                                setReport({})
                                setData({ ...data, p_date_start: e.currentTarget.value })
                            }}
                        />
                        <Input
                            classNameParent="col-6 mb-4"
                            type="date"
                            icon="far fa-calendar-alt"
                            placeholder="Fecha Fin"
                            text="Fecha Fin"
                            required
                            value={data.p_date_end}
                            onChange={e => {
                                setReport({})
                                setData({ ...data, p_date_end: e.currentTarget.value })
                            }}
                        />
                        <Select
                            text="Sucursal"
                            classNameParent="col-md-6 mb-2"
                            options={branchOffice.map(p => {
                                return {
                                    label: p.key_branch_office + ' - ' + p.name_branch_office,
                                    value: p.id_branch_office
                                }
                            })}
                            onChange={e => {
                                setReport({})
                                setBranchOfficeSelected(e.value)
                                Controllers.product.get_product({
                                    p_id_branch_office: e.value
                                }).then(res => {
                                    setProducts(res.data)
                                }).catch(req => Helpers.promise.catch({ req, toast }))
                            }}
                        />
                        <Select
                            text="Productos"
                            classNameParent="col-md-6 mb-2"
                            options={products.map(p => {
                                return {
                                    label: p.key_product + ' - ' + p.name_product,
                                    value: p.id_product
                                }
                            })}
                            onChange={e => {
                                setReport({})
                                setProductSelected(e.value)
                            }}
                        />
                    </FormCustom>
                    {!loading && reporte?.filename ? <div className='mt-4 mb-2'>
                        <a href={reporte?.excel} download={reporte?.filename}>
                            <Button size="sm" variant="success">
                                <i className="fa fa-file-excel mr-2"></i>Descargar Reporte Excel
                            </Button>
                        </a>
                    </div> : ''}
                </Card.Body>
            </Card>
        </ReactPlaceholder>
    </>
}

export default Kardex